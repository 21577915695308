import { ICMsites, ICMsitesGrid, IContractManfacturer } from './CMsites.interfaces';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';

export function flatCMSiteData(data: ICMsites[]): ICMsitesGrid[] {
  let flattedData: ICMsitesGrid[] = [];
  data?.forEach((element) => {
    var elem: ICMsitesGrid = {
      contractManufacturerId: element.contractManufacturer.contractManufacturerId,
      cmSiteName: element.cmSiteName,
      contractManufacturerName:element.contractManufacturer.contractManufacturerName,
    };
    
    flattedData.push(elem);
  });
  return flattedData;
}
