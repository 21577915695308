import { QuoteAction } from 'src/app/core/Admin/quotes/quotes.interfaces';
import { downLoadFile, mapSort } from 'src/app/core/utils';
import { normalizedGridColumns } from 'src/app/features/upload/utils/headers';
import {
  uploadedQuoteGridColumns,
  uploadedQuoteGridDDL,
} from '../utils/headers';
import { ActionTypes } from './quotes.action';

export const INITIAL_QUOTE_STATE = {
  quoteGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
  },
  quoteDetailsGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
  },
  priceHistoryGrid: {
    data: [],
    error: undefined,
    loading: false,
  },
  quotePartsDetails: {
    quoteName: '',
    noOfParts: 0,
    rFQInDate: '',
    dueDate: '',
    status: '',
    priority: '',
    clientName: '',
    clientEmail: ''
  },
  quoteData: {
    id: 0,
    quoteName: '',
    operationStatus: '',
    description: '',
    dueDate: '',
    time: '',
    priority: '',
    estimateTotalPrice: 0,
    quantity: 0,
    fileId: 0,
    currencyId: 0,
    loading: false,
  },

  manFilter: {},
  disFilter: {},
  manGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
  },
  colGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
  },
  cmGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
    filter: [],
  },
  cmFilter: {},
  updateQuote: {},
  sendReminderMail: {},
  
  getQuoteById: {
    data: [],
    error: undefined,
    loading: false,
  },
  generateLoa:{
    message:undefined,
  },
  deleteLoa:{
    message:undefined,
  },
  disGrid: {
    currentPage: 0,
    data: [],
    offset: 1,
    pageSize: 10,
    total: 0,
    error: undefined,
    loading: false,
  },
  filter: undefined,
  sort: {},
  export: {},
  exportPriceHistory: {},
  exportQuotePriceHistory: {},
  normalizationResult: {
    duplicateCount: 0,
    errorCount: 0,
    isNormalized: null,
    normalizedGrid: {
      data: [],
      currentPage: 1,
      pageSize: 15,
    },
    export: {},
  },
  uploadQuoteFile: {
    fileId: 0,
    tempQuoteId: '',
    fileStatus: 0,
  },
  discardQuote: {
    status: undefined,
  },
  validateQuoteFile: {
    loading: false,
    tempQuoteId: null,
    fileId: 0,
    fileStatus: null,
  },
  reviewQuoteGrid: {
    data: [],
    columns: [],
    currentPage: 1,
    pageSize: 15,
  },
  currentQuoteAction: {
    actionType: QuoteAction.UPLOAD,
    //temoQuoteId: sessionStorage.getItem(UPLOAD_TICKET_ID),
    fileId: null,
  },
  submitQuoteAction: {
    loading: false,
    error: undefined,
    quoteFileSubmit: undefined,
  },
  gridActionPopupData: {
    hasError: undefined,
    loading: false,
    error: undefined,
    message: undefined
  },
  ackBySelection: {
    hasError: undefined,
    loading: false,
    error: undefined,
    message: undefined
  },
  selectAllResponses: {
    allResponsesIds: [],
    loading: false,
    error: undefined,
  },
  CreateLoaDTO:{
  loaDTO:undefined,
  },

  loaResponseDTO:{
    supplierId: undefined,
    manfactuererId: undefined,
    error: undefined,
    loading: false,
  },
  getLoadDTO :{}
};

export function QuoteReducer(state = INITIAL_QUOTE_STATE, action: any): any {
  switch (action.type) {
    case ActionTypes.QUOTE_LOAD_DATA: {
      return {
        ...state,
        quoteGrid: {
          ...state.quoteGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.QUOTE_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        quoteGrid: {
          ...state.quoteGrid,
          data: action?.payload?.data?.data,
          currentPage: action?.payload?.data?.currentPage,
          offset: action?.payload?.data?.offset,
          pageSize: action?.payload?.data?.pageSize,
          total: action?.payload?.data?.total,
          loading: false,
        },
      };
    }
    case ActionTypes.QUOTE_LOAD_DATA_ERROR: {
      return {
        ...state,
        quoteGrid: {
          ...state.quoteGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.COL_LOAD_DATA: {
      return {
        ...state,
        colGrid: {
          ...state.colGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.COL_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        colGrid: {
          ...state.colGrid,
          data: action?.payload?.data,

          loading: false,
        },
      };
    }
    case ActionTypes.COL_LOAD_DATA_ERROR: {
      return {
        ...state,
        colGrid: {
          ...state.colGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.QUOTE_MAN_LOAD_DATA: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.QUOTE_MAN_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          data: action.payload.data,
          loading: false,
        },
      };
    }
    case ActionTypes.QUOTE_MAN_LOAD_DATA_ERROR: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.CONTRACT_MAN_LOAD_DATA: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.CONTRACT_MAN_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          data: action.payload.data,
          loading: false,
        },
      };
    }
    case ActionTypes.CONTRACT_MAN_LOAD_DATA_ERROR: {
      return {
        ...state,
        cmGrid: {
          ...state.cmGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.QUOTE_MAN_LOAD_FILTERED_DATA: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.QUOTE_MAN_LOAD_FILTERED_DATA: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          data: action.payload.data,
          loading: false,
        },
      };
    }
    case ActionTypes.QUOTE_MAN_LOAD_DATA_ERROR: {
      return {
        ...state,
        manGrid: {
          ...state.manGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.QUOTE_MAN_FILTER_CHANGED: {
      return {
        ...state,
        manFilter: action?.filter,
      };
    }
    case ActionTypes.CONTRACT_MAN_FILTER_CHANGED: {
      return {
        ...state,
        cmFilter: action?.filter,
      };
    }
    case ActionTypes.QUOTE_DETAILS_COLUMNS_FILTER_CHANGED: {
      return {
        ...state,
        colFilter: action?.filter,
      };
    }
    case ActionTypes.QUOTE_DIS_FILTER_CHANGED: {
      return {
        ...state,
        disFilter: action?.filter,
      };
    }
    case ActionTypes.QUOTE_DIS_LOAD_DATA: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.QUOTE_DIS_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          data: action?.payload?.data,
          loading: false,
        },
      };
    }
    case ActionTypes.QUOTE_DIS_LOAD_DATA_ERROR: {
      return {
        ...state,
        disGrid: {
          ...state.disGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.GET_QUOTE_BY_ID: {
      return {
        ...state,
        getQuoteById: {
          ...state.getQuoteById,
          loading: true,
        },
      };
    }
    case ActionTypes.GET_QUOTE_BY_ID_SUCCESS: {
      return {
        ...state,
        getQuoteById: {
          ...state.getQuoteById,
          data: action?.payload?.data,
          loading: false,
        },
      };
    }
    case ActionTypes.GET_QUOTE_BY_ID_ERROR: {
      return {
        ...state,
        getQuoteById: {
          ...state.getQuoteById,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_QUOTE: {
      return {
        ...state,
        updateQuote: {
          ...state.updateQuote,
          loading: true,
        },
      };
    }
    case ActionTypes.UPDATE_QUOTE_SUCCESS: {
      return {
        ...state,
        updateQuote: action?.payload,
        loading: false,
      };
    }
    case ActionTypes.UPDATE_QUOTE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case ActionTypes.SEND_REMINDER_MAIL: {
      return {
        ...state,
        sendReminderMail: false,
        loading: true,
      };
    }
    case ActionTypes.SEND_REMINDER_MAIL_SUCCESS: {
      return {
        ...state,
        sendReminderMail: action?.payload,
        loading: false,
      };
    }
    case ActionTypes.SEND_REMINDER_MAIL_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case ActionTypes.QUOTE_PAGE_CHANGED: {
      return {
        ...state,
        quoteGrid: {
          ...state.quoteGrid,
          currentPage: action.payload,
        },
      };
    }

    case ActionTypes.QUOTE_FILTER_CHANGED: {
      return {
        ...state,
        filter: action?.filter,
      };
    }

    case ActionTypes.QUOTE_SORT_CHANGED: {
      return {
        ...state,
        sort: mapSort(action?.payload),
      };
    }

    case ActionTypes.QUOTE_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        quoteGrid: {
          ...state.quoteGrid,
          pageSize: action.payload,
        },
      };
    }
    case ActionTypes.QUOTE_DETAIL_PAGE_CHANGED: {
      return {
        ...state,
        quoteDetailsGrid: {
          ...state.quoteGrid,
          currentPage: action.payload,
        },
      };
    }

    case ActionTypes.QUOTE_DETAIL_FILTER_CHANGED: {
      return {
        ...state,
        filter: action?.filter,
      };
    }

    case ActionTypes.QUOTE_DETAIL_SORT_CHANGED: {
      return {
        ...state,
        sort: mapSort(action?.payload),
      };
    }

    case ActionTypes.QUOTE_DETAIL_PAGE_SIZE_CHANGED: {
      return {
        ...state,
        quoteDetailsGrid: {
          ...state.quoteGrid,
          pageSize: action.payload,
        },
      };
    }
    case ActionTypes.QUOTE_EXPORT_DATA: {
      return {
        ...state,
        export: {
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.QUOTE_EXPORT_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        export: {
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.QUOTE_EXPORT_DATA_ERROR: {
      return {
        ...state,
        exportPriceHistory: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.PRICE_HISTORY_EXPORT_DATA: {
      return {
        ...state,
        exportPriceHistory: {
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.PRICE_HISTORY_EXPORT_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        exportPriceHistory: {
          loading: false,
          error: undefined,
        },
      };
    }

   
    case ActionTypes.QUOTE_PRICE_HISTORY_EXPORT_DATA: {
      return {
        ...state,
        exportQuotePriceHistory: {
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.QUOTE_PRICE_HISTORY_EXPORT_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        exportQuotePriceHistory: {
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.QUOTE_PRICE_HISTORY_EXPORT_DATA_ERROR: {
      return {
        ...state,
        exportQuotePriceHistory: {
          loading: false,
          error: action.payload,
        },
      };
    }
    
    case ActionTypes.PRICE_HISTORY_EXPORT_DATA_ERROR: {
      return {
        ...state,
        exportPriceHistory: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.GET_QUOTE_DETAILS_RECORDS_BY_ID: {
      return {
        ...state,
        quoteDetailsGrid: {
          ...state.quoteDetailsGrid,
          loading: true,
        },
        loading: true,
      };
    }
    case ActionTypes.GET_QUOTE_DETAILS_RECORDS_BY_ID_SUCCESS: {
      return {
        ...state,
        quoteDetailsGrid: {
          data: action?.payload?.data?.data,
          currentPage: action?.payload?.data?.currentPage,
          offset: action?.payload?.data?.offset,
          pageSize: action?.payload?.data?.pageSize,
          total: action?.payload?.data?.total,
          loading: false,
        },
        loading: false,
      };
    }
    case ActionTypes.GET_QUOTE_DETAILS_RECORDS_BY_ID_ERROR: {
      return {
        ...state,
        quoteDetailsGrid: {
          ...state.quoteDetailsGrid,
          loading: false,
          error: action.payload,
        },
        loading: false,
      };
    }

    case ActionTypes.PRICE_HISTORY_LOAD_DATA: {
      return {
        ...state,
        priceHistoryGrid: {
          ...state.priceHistoryGrid,
          loading: true,
        },
        loading: true,
      };
    }
    case ActionTypes.PRICE_HISTORY_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        priceHistoryGrid: {
          data: action?.payload?.data,
          loading: false,
        },
        loading: false,
      };
    }
    case ActionTypes.PRICE_HISTORY_LOAD_DATA_ERROR: {
      return {
        ...state,
        priceHistoryGrid: {
          ...state.priceHistoryGrid,
          loading: false,
          error: action.payload,
        },
        loading: false,
      };
    }


    case ActionTypes.UPDATE_QUOTE_DATA: {
      return {
        ...state,
        quoteData: { ...state.quoteData, loading: true },
        loading: true,
        error: undefined,
      };
    }
    case ActionTypes.UPDATE_QUOTE_DATA_SUCCESS: {
      return {
        ...state,
        quoteData: {
          ...action?.payload?.data,
          loading: false,
          error: undefined,
        },
      };
    }
    case ActionTypes.UPDATE_QUOTE_DATA_ERROR: {
      return {
        ...state,
        quoteData: {
          ...state.quoteData,
        },
        loading: false,
        error: action.payload,
      };
    }
    case ActionTypes.REVIEW_QUOTE_DATA: {
      return {
        ...state,
        reviewQuoteGrid: {
          ...state.reviewQuoteGrid,
          columns: [],
          DDL: [],
          data: [],
          loading: true,
          error: undefined,
        },
        normalizationResult: {
          ...state.normalizationResult,
          isNormalized: null,
        },
      };
    }
    // Request
    case ActionTypes.REVIEW_QUOTE_DATA_SUCCESS: {
      return {
        ...state,
        reviewQuoteGrid: {
          ...state.reviewQuoteGrid,
          columns: uploadedQuoteGridColumns(
            action.payload?.data?.rows[0]?.cells
          ),
          DDL: uploadedQuoteGridDDL(action.payload?.data?.columns),
          data: action.payload?.data?.rows,
          loading: false,
          error: undefined,
        },
        currentQuoteAction: {
          ...state.currentQuoteAction,
          fileId: action.payload?.data?.fileId,
        },
        uploadQuoteFile: {
          ...state.uploadQuoteFile,
          loading: false,
        },
      };
    }
    case ActionTypes.REVIEW_QUOTE_DATA_ERROR: {
      return {
        ...state,
        reviewQuoteGrid: {
          ...state.reviewQuoteGrid,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.MAP_Quote_GRID_DATA: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          isNormalized: null,
          loading: true,
        },
        updateQuote: null,
        submitQuoteAction: null,
      };
    }
    case ActionTypes.MAPPED_QUOTE_DATA_SUCCESS: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          duplicateCount: action.payload?.data?.duplicateCount,
          errorCount: action.payload?.data?.errorCount,
          loading: false,
          isNormalized: true,
          normalizedGrid: {
            ...state.normalizationResult.normalizedGrid,
            columns: normalizedGridColumns(action.payload?.data?.mappedColumns),
            data: action.payload?.data?.rows,
          },
        },
      };
    }
    case ActionTypes.MAPPED_QUOTE_DATA_ERROR: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          loading: false,
          isNormalized: false,
          error: action.payload,
        },
      };
    }
    case ActionTypes.DISCARD_QUOTE: {
      return {
        ...state,
        discardQuote: {
          ...state.discardQuote,
          loading: true,
        },
        currentQuoteAction: {
          ...state.currentQuoteAction,
          ticketId: null,
          fileId: null,
        },
      };
    }
    case ActionTypes.DISCARD_QUOTE_SUCCESS: {
      return {
        ...state,
        discardQuote: {
          ...state.discardQuote,
          status: 'success',
          loading: false,
        },
      };
    }
    case ActionTypes.DISCARD_QUOTE_ERROR: {
      return {
        ...state,
        discardQuote: {
          ...state.discardQuote,
          loading: false,
          error: action.payload,
        },
      };
    }

    case ActionTypes.SET_CURRENT_QUOTE_ACTION_DATA: {
      //Save data in local storage as well to help if user refresh page
      //sessionStorage.setItem(UPLOAD_TICKET_ID, action.ticketId);

      return {
        ...state,
        currentQuoteAction: {
          ...state.currentQuoteAction,
          actionType: action.actionType,
          fileId: action.fileId,
        },
      };
    }
    case ActionTypes.VALIDATE_QUOTE_FILE_STATUS: {
      return {
        ...state,
        validateQuoteFile: {
          ...state.validateQuoteFile,
          loading: true,
        },
        currentQuoteAction: {
          ...state.currentQuoteAction,
          fileId: null,
        },
      };
    }
    case ActionTypes.EXPORT_QUOTE_DETAILS_GRID_DATA: {
      return {
        ...state,
        quoteDetailsGrid: {
          ...state.quoteDetailsGrid,
          export: {
            loading: true,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_QUOTE_DETAILS_GRID_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        quoteDetailsGrid: {
          ...state.quoteDetailsGrid,
          export: {
            loading: false,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_QUOTE_DETAILS_GRID_DATA_ERROR: {
      return {
        ...state,
        quoteDetailsGrid: {
          ...state.quoteDetailsGrid,
          export: {
            loading: false,
            error: action.payload,
          },
        },
      };
    }

    case ActionTypes.VALIDATE_QUOTE_FILE_STATUS_SUCCESS: {
      return {
        ...state,
        validateQuoteFile: {
          ...state.validateQuoteFile,
          fileStatus: action.payload?.data?.fileStatus,
          fileId: action.payload?.data?.id,
          tempQuoteId: action.payload?.data?.tempQuoteId,
          loading: false,
        },
        currentQuoteAction: {
          ...state.currentQuoteAction,
          fileId: action.payload?.data?.id,
        },
      };
    }

    case ActionTypes.VALIDATE_QUOTE_FILE_STATUS_ERROR: {
      return {
        ...state,
        validateQuoteFile: {
          ...state.validateQuoteFile,
          fileStatus: null,
          fileId: null,
          tempQuoteId: null,
          loading: false,
        },
      };
    }
    case ActionTypes.UPLOAD_Quote_FILE: {
      return {
        ...state,
        uploadQuoteFile: {
          ...state.uploadQuoteFile,
          fileId: null,
          tempQuoteId: null,
          isActive: null,
          loading: true,
          error: undefined,
        },
      };
    }
    case ActionTypes.UPLOAD_Quote_FILE_SUCCESS: {
      return {
        ...state,
        uploadQuoteFile: {
          ...state.uploadQuoteFile,
          fileId: action.payload?.data?.id,
          tempQuoteId: action.payload?.data?.tempQuoteId,
          isActive: action.payload?.data?.isActive,
          error: undefined,
          loading: false,
        },
      };
    }
    case ActionTypes.UPLOAD_Quote_FILE_ERROR: {
      return {
        ...state,
        uploadQuoteFile: {
          ...state.uploadQuoteFile,
          loading: false,
          error: action.payload,
        },
      };
    }

    //
    case ActionTypes.EXPORT_NORMALIZED_GRID_DATA: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          export: {
            loading: true,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_NORMALIZED_GRID_DATA_SUCCESS: {
      downLoadFile(action.payload, 'application/ms-excel', action.fileName);
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          export: {
            loading: false,
            error: undefined,
          },
        },
      };
    }
    case ActionTypes.EXPORT_NORMALIZED_GRID_DATA_ERROR: {
      return {
        ...state,
        normalizationResult: {
          ...state.normalizationResult,
          export: {
            loading: false,
            error: action.payload,
          },
        },
      };
    }

    case ActionTypes.SUBMIT_QUOTE_FILE: {
      return {
        ...state,
        submitQuoteAction: {
          loading: true,
          error: undefined,
          quoteFileSubmit: {
            data: null,
          },
        },
      };
    }
    case ActionTypes.SUBMIT_QUOTE_FILE_SUCCESS: {
      return {
        ...state,
        submitQuoteAction: {
          ...state.submitQuoteAction,
          loading: false,
          quoteFileSubmit: action.payload,
        },
      };
    }
    case ActionTypes.SUBMIT_QUOTE_FILE_ERROR: {
      return {
        ...state,
        submitQuoteAction: {
          ...state.submitQuoteAction,
          loading: false,
          error: action.payload,
          quoteFileSubmit: action.payload,
        },
      };
    }
    case ActionTypes.AWARD_BY_SELECTION: {
      return {
        ...state,
        gridActionPopupData: {
          message: undefined,
          hasError: undefined,
          loading: true,
          error: undefined
        },
      };
    }
    case ActionTypes.AWARD_BY_SELECTION_SUCCESS: {
      return {
        ...state,
        gridActionPopupData: {
          ...state.gridActionPopupData,
          message: action.payload.data,
          hasError: false,
          loading: false
        },
      };
    }
    case ActionTypes.AWARD_BY_SELECTION_ERROR: {
      return {
        ...state,
        gridActionPopupData: {
          ...state.gridActionPopupData,
          hasError: true,
          loading: false,
          error: action.payload
        },
      };
    }

    case ActionTypes.GENERATE_LOAD_FILE: {
      return {
        ...state,
        generateLoa: {
          message: undefined,
          hasError: undefined,
          loading: true,
          error: undefined
        },
      };
    }
    case ActionTypes.GENERATE_LOAD_FILE_SUCCESS: {
      return {
        ...state,
        generateLoa: {
          ...state.generateLoa,
          message: 'succeed',
          hasError: false,
          loading: false
        },
      };
    }
    case ActionTypes.GENERATE_LOAD_FILE_ERROR: {
      return {
        ...state,
        generateLoa: {
          ...state.generateLoa,
          hasError: true,
          loading: false,
          error: action.payload
        },
      };
    }

    case ActionTypes.DELETE_LOA: {
      return {
        ...state,
        deleteLoa: {
          message: undefined,
          hasError: undefined,
          loading: true,
          error: undefined
        },
      };
    }
    case ActionTypes.DELETE_LOA_SUCCESS: {
      return {
        ...state,
        deleteLoa: {
          ...state.deleteLoa,
          message: 'succeed',
          hasError: false,
          loading: false
        },
      };
    }
    case ActionTypes.DELETE_LOA_ERROR: {
      return {
        ...state,
        deleteLoa: {
          ...state.deleteLoa,
          hasError: true,
          loading: false,
          error: action.payload
        },
      };
    }

    case ActionTypes.ACK_BY_SELECTION: {
      return {
        ...state,
        gridActionPopupData: {
          message: undefined,
          hasError: undefined,
          loading: true,
          error: undefined
        },
      };
    }
    case ActionTypes.ACK_BY_SELECTION_SUCCESS: {
      return {
        ...state,
        gridActionPopupData: {
          ...state.gridActionPopupData,
          message: action.payload.data,
          hasError: false,
          loading: false
        },
      };
    }
    case ActionTypes.ACK_BY_SELECTION_ERROR: {
      return {
        ...state,
        gridActionPopupData: {
          ...state.gridActionPopupData,
          hasError: true,
          loading: false,
          error: action.payload
        },
      };
    }
    case ActionTypes.RESET_AWARD_BY_SELECTION_STATE: {
      return {
        ...state,
        gridActionPopupData: {
          ...state.gridActionPopupData,
          message: undefined,
          hasError: false,
          loading: false
        },
        selectAllResponses: {
          allResponsesIds: undefined,
          loading: true,
          error: undefined
        },
      };
    }
    case ActionTypes.GET_ALL_FILTERED_RESPONSE_IDS: {
      return {
        ...state,
        selectAllResponses: {
          allResponsesIds: undefined,
          loading: true,
          error: undefined
        },
      };
    }
    case ActionTypes.GET_ALL_FILTERED_RESPONSE_IDS_SUCCESS: {
      return {
        ...state,
        selectAllResponses: {
          ...state.selectAllResponses,
          allResponsesIds: action.payload.data,
          loading: false
        },
      };
    }
    case ActionTypes.GET_ALL_FILTERED_RESPONSE_IDS_ERROR: {
      return {
        ...state,
        selectAllResponses: {
          ...state.selectAllResponses,
          allResponsesIds: undefined,
          loading: false,
          error: action.payload
        },
      };
    }
    case ActionTypes.CANCEL_ACK_BY_SELECTION: {
      return {
        ...state,
        gridActionPopupData: {
          message: undefined,
          hasError: undefined,
          loading: true,
          error: undefined
        },
      };
    }
    case ActionTypes.CANCEL_ACK_BY_SELECTION_SUCCESS: {
      return {
        ...state,
        gridActionPopupData: {
          ...state.gridActionPopupData,
          message: action.payload.data,
          hasError: false,
          loading: false
        },
      };
    }
    case ActionTypes.CANCEL_ACK_BY_SELECTION_ERROR: {
      return {
        ...state,
        gridActionPopupData: {
          ...state.gridActionPopupData,
          hasError: true,
          loading: false,
          error: action.payload
        },
      };
    }
    case ActionTypes.GENERATE_LOA_BY_SELECTION: {
      return {
        ...state,
        gridActionPopupData: {
          message: undefined,
          hasError: undefined,
          loading: true,
          error: undefined
        },
      };
    }
    case ActionTypes.GENERATE_LOA_BY_SELECTION_SUCCESS: {
      return {
        ...state,
        gridActionPopupData: {
          ...state.gridActionPopupData,
          message: action.payload.data,
          hasError: false,
          loading: false
        },
      };
    }
    case ActionTypes.GENERATE_LOA_BY_SELECTION_ERROR: {
      return {
        ...state,
        gridActionPopupData: {
          ...state.gridActionPopupData,
          hasError: true,
          loading: false,
          error: action.payload
        },
      };
    }

  
    case ActionTypes.CREATE_LOA: {
      return {
        ...state,
        loaResponseDTO: {
          ...state.loaResponseDTO,
          loading: true,
          error: undefined
        }
      };
    }
    case ActionTypes.CREATE_LOA_SUCCESS: {
      return {
        ...state,
        loaResponseDTO: {
          ...action.payload,
          loading: false,
          error: undefined
        }
      };
    }
    case ActionTypes.EMPTY_LOA: {
      return {
        ...state,
        loaResponseDTO: {
          loaId: undefined,
          supplierId: undefined,
          manfactureId: undefined,
          loading: false,
          error: undefined
        }
      };
    }
    case ActionTypes.EMPTY_GET_LOA: {
      return {
        ...state,
        getLoadDTO: {
          supplierName: undefined,
          manfacutererName: undefined,
          loaList: undefined,
          columns: undefined,
          loading: false,
          error: undefined
        }
      };
    }
    case ActionTypes.EMPTY_GENERATE_LOA: {
      return {
        ...state,
        generateLoa: {
          message: undefined,
          hasError: undefined,
          loading: true,
          error: undefined
        },
      };
    }
    case ActionTypes.CREATE_LOA_ERROR: {
      return {
        ...state,
        loaResponseDTO: {
          ...state.loaResponseDTO,
          loading: false,
          error: action.payload
        }
      };
    }
    case ActionTypes.GET_LOA: {
      return {
        ...state,
        getLoadDTO: {
          ...state.getLoadDTO,
          loading: true,
          error: undefined
        }
      };
    }
    case ActionTypes.GET_LOA_SUCCESS: {
      return {
        ...state,
        getLoadDTO: {
          ...action.payload,
          loading: false,
          error: undefined
        }
      };
    }
    case ActionTypes.GET_LOA_ERROR: {
      return {
        ...state,
        getLoadDTO: {
          ...state.getLoadDTO,
          loading: false,
          error: action.payload
        }
      };
    }
    case ActionTypes.QUOTE_DETAILS_COLUMNS_LOAD_DATA: {
      return {
        ...state,
        colGrid: {
          ...state.colGrid,
          loading: true,
        },
      };
    }
    case ActionTypes.QUOTE_DETAILS_COLUMNS_LOAD_DATA_SUCCESS: {
      return {
        ...state,
        colGrid: {
          ...state.colGrid,
          data: action?.payload?.data,

          loading: false,
        },
      };
    }
    case ActionTypes.QUOTE_DETAILS_COLUMNS_LOAD_DATA_ERROR: {
      return {
        ...state,
        colGrid: {
          ...state.colGrid,
          loading: false,
          error: action.payload,
        },
      };
    }
    
    default: {
      return state;
    }
  }
}
