import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Dialog } from '../../models/model.interface';
import { actionComponentData, actions } from '../../shared.interface';

@Injectable({
  providedIn: 'root',
})
export class DialogShareService {
  dialog = new Subject<object>();
  openDialog = new Subject<actions>();

  constructor() {}
  sendMessage(message: any) {
    this.dialog.next({ res: message });
  }
 
  clearMessages() {
  //  this.dialog.next();
  }

  allowOpenDialog(componentObject: actions) {
    this.openDialog.next(componentObject);
  }

  getMessage(): Observable<any> {
    return this.dialog.asObservable();
  }
}
