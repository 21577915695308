import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import {
  IRegisteredUser,
  IRegisteredUserAPIObj,
} from './registered-users.interface';

export function flatRegisterdData(
  data: IRegisteredUserAPIObj[]
): IRegisteredUser[] {
  if (data?.length > 0) {
    return [
      ...data?.map((c: any) => {
        return {
          ...c,
          roleId: c.role?.roleId,
          roleName: c.role?.roleName,
          companyTypeId: c.companyType?.companyTypeId,
          companyTypeName: c.companyType?.companyTypeName,
        };
      }),
    ];
  } else {
    return [];
  }
}
export function mapRegisteredUsersAPISort(sort?: SortDescriptor[]) {
  var kendoSort = _.isArray(sort) ? sort[0] : sort;
  var dir = kendoSort?.dir === 'desc' ? 'desc' : 'asc';
  if (!kendoSort?.field) return {};
  switch (kendoSort?.field) {
    case 'companyTypeName':
      return { columnName: 'companyType.companyTypeName', direction: dir };
    case 'roleName':
      return { columnName: 'role.roleName', direction: dir };
    case 'status':
      return { columnName: 'userStatus.statusName', direction: dir };
  
    default:
      return { columnName: kendoSort?.field, direction: dir };
  }
}

export function mapRegisteredUsersAPIFilter(gridFilters: any) {
  var kendoFilter = _.isArray(gridFilters) ? gridFilters[0] : gridFilters;
  var kendoFilters = _.isArray(kendoFilter?.filters)
    ? kendoFilter?.filters[0]
    : kendoFilter;

  switch (kendoFilters?.field) {
    case 'roleName':
      return [
        {
          columnName: 'role.roleName',
          value: kendoFilters?.value,
        },
      ];
    case 'companyTypeName':
      return [
        {
          columnName: 'companyType.companyTypeName',
          value: kendoFilters?.value,
        },
      ];
      case 'status':
        return [
          {
            columnName: 'userStatus.statusName',
            value: kendoFilters?.value,
          },
        ];
  
    default:
      return [
        {
          columnName: kendoFilters?.field,
          value: kendoFilters?.value,
        },
      ];
  }
}
