import { ApiService } from '../../../core/api/api.service';
import { environment } from '../../../../../src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IGridResponse } from 'src/app/shared/shared.interface';
import { IYearCurrencyExchange } from '../year-currencies/year-currencies.interface';
import { gridRequest } from '../../grid.request';

@Injectable({
  providedIn: 'root',
})
export class ServiceMiddleware {
  public BaseURL: string = `${environment.baseUrls.serviceURL}`;
  public logOutURL: string = `${environment.baseUrls.serviceSecurity}`;
  public requestData: object = {};
  constructor(private apiService: ApiService, private _http: HttpClient) {}

  public getList(ApiUrl: string, model: object) {
    return this.apiService.post(this.BaseURL + ApiUrl, model);
  }
  public Get(ApiUrl: string) {
    return this.apiService.get(this.BaseURL + ApiUrl);
  }
  public AddRecord(ApiUrl: string, model: object) {
    return this.apiService.post(this.BaseURL + ApiUrl, model);
  }
  public LogOut(ApiUrl: string, model: object) {
    return this.apiService.post(this.logOutURL + ApiUrl, model);
  }
  public EditRecord(ApiUrl: string, model: object) {
    return this.apiService.put(this.BaseURL + ApiUrl, model);
  }
  public PutTicket(ApiUrl: string) {
    return this.apiService.put(this.BaseURL + ApiUrl, {});
  }
  public getYearExchange(page?: number, size?: number, ApiUrl?: string) {
    const request = gridRequest(page, size);
    return this.apiService.post<IGridResponse<IYearCurrencyExchange>>(
      this.BaseURL + ApiUrl,
      request
    );
  }
  public GetSingleRecord(ApiUrl: string) {
    return this.apiService.get(this.BaseURL + ApiUrl);
  }

  public DeleteRecord(ApiUrl: string) {
    return this.apiService.delete(this.BaseURL + ApiUrl);
  }
  public exportList(ApiUrl: string) {
    return this.apiService.getBlob(this.BaseURL + ApiUrl);
  }
  public exportFilteredList(ApiUrl: string, model: object) {
    return this.apiService.getBlobFiltered(this.BaseURL + ApiUrl, model);
  }
}
