import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubLayoutComponent } from './components/sub-layout/sub-layout.component';
import { RouterModule } from '@angular/router';
import { NewWindowComponent } from './components/Modals/new-window/new-window.component';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { GridComponent } from './components/grid/grid/grid.component';
import { AppButtonComponent } from './components/button/button.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { TicketCardComponent } from './components/ticket-card/ticket-card.component';
import { InfoMessageComponent } from './components/info-message/info-message.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FileUploadComponent } from './components/file-upload/file-uploadcomponent';
import { PopoverContainerComponent } from './components/popover/popover.component';
import { PopoverModule } from '@progress/kendo-angular-tooltip';
import { StepperComponent } from './components/stepper/stepper.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DialogMessageComponent } from './components/Modals/dialog-message/dialog-message.component';
import { DropdownListComponent } from './components/dropdown-list/dropdown-list.component';
import { TextBoxComponent } from './components/text-box/text-box.component';
import { ContainerComponent } from './components/container/container.component';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridComponentComponent } from './components/grid-component/grid-component.component';

import { AddEditFormComponent } from './components/add-edit-form/add-edit-form.component';
import { AddEditGridRecordComponent } from './components/add-edit-grid-record/add-edit-grid-record.component';
import { TreeViewComponent } from './components/tree-view/tree-view.component';
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { DeletePopupComponent } from './components/delete-popup/delete-popup.component';
import { PopupComponent } from './components/popup/popup.component';
 
@NgModule({
  declarations: [
    SubLayoutComponent,
    NewWindowComponent,
    GridComponent,
    AppButtonComponent,
    ErrorMessageComponent,
    TicketCardComponent,
    InfoMessageComponent,
    NavBarComponent,
    FileUploadComponent,
    PopoverContainerComponent,
    StepperComponent,
    SpinnerComponent,
    DialogMessageComponent,
    DropdownListComponent,
    TextBoxComponent,
    ContainerComponent,
    CheckBoxComponent,
    AddEditFormComponent,
    GridComponentComponent,
    AddEditGridRecordComponent,
    TreeViewComponent,
    DeletePopupComponent,
    PopupComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    WindowModule,
    ButtonsModule,
    TextBoxModule,
    DropDownsModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    UploadsModule,
    PopoverModule,
    LayoutModule,
    IndicatorsModule,
    DialogsModule,
    ExcelModule,
    DateInputsModule,
    TreeViewModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TextBoxModule,
    PopoverModule,
    DropDownsModule,
    SubLayoutComponent,
    NewWindowComponent,
    GridComponent,
    AppButtonComponent,
    ErrorMessageComponent,
    TicketCardComponent,
    InfoMessageComponent,
    NavBarComponent,
    FileUploadComponent,
    PopoverContainerComponent,
    StepperComponent,
    SpinnerComponent,
    DialogMessageComponent,
    DropdownListComponent,
    CheckBoxComponent,
    DialogsModule,
    DateInputsModule,
    InputsModule,
    DropDownsModule,
    AddEditFormComponent,
    GridComponentComponent,
    AddEditGridRecordComponent,
    TreeViewComponent,
    TreeViewModule,
    ButtonsModule

  ],
})
export class SharedModule {}
