
import { Action } from '@ngrx/store';
import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import {
  IDistributor,
  IDistributorType,
} from 'src/app/core/Admin/distributor/distributor.interfaces';
import {
  ICommodity,
  IManufacturerDistributors,
  manDisRequest,
} from 'src/app/core/Admin/manufacturer-distributors/manufacturer-distributors.interface';
import {
  IManufacturers,
  IManufacturerStatus,
} from 'src/app/core/Admin/manufacturer/manufacturer.interfaces';
import {
  GridHeaderData,
  IFilterModel,
  ISortModel,
} from 'src/app/shared/shared.interface';
import { IRegisteredUser } from 'src/app/core/Admin/users/registered-users/registered-users.interface';
import { actionType, LoaUpdateStatusDTO, ExportLoaFileDTO } from 'src/app/core/Admin/loa/loas.interfaces';
import { UploadTypes } from 'src/app/core/Admin/admin.interfaces';




export enum ActionTypes {


  //-----------------------CustomerSiteExportGrid_DATA-------------------------------------
  CustomerSite_EXPORT_DATA='CustomerSite_EXPORT_DATA',
  CustomerSite_EXPORT_DATA_SUCCESS='CustomerSite_EXPORT_DATA_SUCCESS',
  CustomerSite_EXPORT_DATA_ERROR='CustomerSite_EXPORT_DATA_ERROR',





//-----------------------Division_EXPORT_DATA-------------------------------------
  Divisions_EXPORT_DATA='Divisions_EXPORT_DATA',
  Divisions_EXPORT_DATA_SUCCESS='Divisions_EXPORT_DATA_SUCCESS',
  Divisions_EXPORT_DATA_ERROR='Divisions_EXPORT_DATA_ERROR',
  /////////////////////////////////////////////////TICKET/////////////////////////////////////////////////
  TICKET_PAGE_SIZE_CHANGED = 'TICKET_PAGE_SIZE_CHANGED',
  TICKET_LOAD_DATA = 'TICKET_LOAD_DATA',
  TICKET_LOAD_DATA_SUCCESS = 'TICKET_LOAD_DATA_SUCCESS',
  TICKET_LOAD_DATA_ERROR = 'TICKET_LOAD_DATA_ERROR',

  SET_VALIDATION_ERROR = 'SET_VALIDATION_ERROR',

  TICKET_LOAD_DATA_RECORD = 'TICKET_LOAD_DATA_RECORD',
  TICKET_LOAD_DATA_RECORD_SUCCESS = 'TICKET_LOAD_DATA_RECORD_SUCCESS',
  TICKET_LOAD_DATA_RECORD_ERROR = 'TICKET_LOAD_DATA_RECORD_ERROR',

  TICKET_EXPORT_DATA = 'TICKET_EXPORT_DATA',
  TICKET_EXPORT_DATA_SUCCESS = 'TICKET_EXPORT_DATA_SUCCESS',
  TICKET_EXPORT_DATA_ERROR = 'TICKET_EXPORT_DATA_ERROR',

  TICKET_EDIT_RECORD = 'TICKET_EDIT_RECORD',
  TICKET_EDIT_RECORD_SUCCESS = 'TICKET_EDIT_RECORD_SUCCESS',
  TICKET_EDIT_RECORD_ERROR = 'TICKET_EDIT_RECORD_ERROR',

  TICKET_DELETE_RECORD = 'TICKET_DELETE_RECORD',
  TICKET_DELETE_RECORD_SUCCESS = 'TICKET_DELETE_RECORD_SUCCESS',
  TICKET_DELETE_RECORD_ERROR = 'TICKET_DELETE_RECORD_ERROR',
  LOG_OUT = 'LOG_OUT',

  TICKET_APPROVE_RECORD = 'TICKET_APPROVE_RECORD',
  TICKET_APPROVE_RECORD_SUCCESS = 'TICKET_APPROVE_RECORD_SUCCESS',
  TICKET_APPROVE_RECORD_ERROR = 'TICKET_APPROVE_RECORD_ERROR',

  TICKET_REJECT_RECORD = 'TICKET_REJECT_RECORD',
  TICKET_REJECT_RECORD_SUCCESS = 'TICKET_REJECT_RECORD_SUCCESS',
  TICKET_REJECT_RECORD_ERROR = 'TICKET_REJECT_RECORD_ERROR',

  TICKET_SORT_CHANGED = 'TICKET_SORT_CHANGED',
  TICKET_FILTER_CHANGED = 'TICKET_FILTER_CHANGED',
  TICKET_PAGE_CHANGED = 'TICKET_PAGE_CHANGED',


  GET_TICKET_ASSIGNEE = 'GET_TICKET_ASSIGNEE',
  GET_TICKET_ASSIGNEE_SUCCESS = 'GET_TICKET_ASSIGNEE_SUCCESS',
  GET_TICKET_ASSIGNEE_ERROR = 'GET_TICKET_ASSIGNEE_ERROR',

  GET_TICKET_YEAR = 'GET_TICKET_YEAR',
  GET_TICKET_YEAR_SUCCESS = 'GET_TICKET_YEAR_SUCCESS',
  GET_TICKET_YEAR_ERROR = 'GET_TICKET_YEAR_ERROR',

  GET_TICKET_TYPE = 'GET_TICKET_TYPE',
  GET_TICKET_TYPE_SUCCESS = 'GET_TICKET_TYPE_SUCCESS',
  GET_TICKET_TYPE_ERROR = 'GET_TICKET_TYPE_ERROR',

  REVIEW_TICKET_LOAD_DATA = 'REVIEW_TICKET_LOAD_DATA',
  REVIEW_TICKET_LOAD_DATA_SUCCESS = 'REVIEW_TICKET_LOAD_DATA_SUCCESS',
  REVIEW_TICKET_LOAD_DATA_ERROR = 'REVIEW_TICKET_LOAD_DATA_ERROR',

  REVIEW_TICKET_APPROVE = 'REVIEW_TICKET_APPROVE',
  REVIEW_TICKET_APPROVE_SUCCESS = 'REVIEW_TICKET_APPROVE_SUCCESS',
  REVIEW_TICKET_APPROVE_ERROR = 'REVIEW_TICKET_APPROVE_ERROR',

  REVIEW_TICKET_REJECT = 'REVIEW_TICKET_REJECT',
  REVIEW_TICKET_REJECT_SUCCESS = 'REVIEW_TICKET_REJECT_SUCCESS',
  REVIEW_TICKET_REJECT_ERROR = 'REVIEW_TICKET_REJECT_ERROR',

  EXPORT_COMPONENT_DATA = '[Admin] EXPORT_COMPONENT_DATA',
  EXPORT_COMPONENT_DATA_SUCCESS = '[Admin] EXPORT_COMPONENT_DATA_SUCCESS',
  EXPORT_COMPONENT_DATA_ERROR = '[Admin] EXPORT_COMPONENT_DATA_ERROR',

  EXPORT_TICKET_REVIEW = '[Admin] EXPORT_TICKET_REVIEW',
  EXPORT_TICKET_REVIEW_SUCCESS = '[Admin] EXPORT_TICKET_REVIEW_SUCCESS',
  EXPORT_TICKET_REVIEW_ERROR = '[Admin] EXPORT_TICKET_REVIEW_ERROR',

  /////////////////////////////////////////////////BUISNESS UNIT/////////////////////////////////////////////////
  BUISNESS_UNIT_LOAD_DATA = 'BUISNESS_UNIT_LOAD_DATA',
  BUISNESS_UNIT_LOAD_DATA_SUCCESS = 'BUISNESS_UNIT_LOAD_DATA_SUCCESS',
  BUISNESS_UNIT_LOAD_DATA_ERROR = 'BUISNESS_UNIT_LOAD_DATA_ERROR',

  BUISNESS_UNIT_EXPORT_DATA = 'BUISNESS_UNIT_EXPORT_DATA',
  BUISNESS_UNIT_EXPORT_DATA_SUCCESS = 'BUISNESS_UNIT_EXPORT_DATA_SUCCESS',
  BUISNESS_UNIT_EXPORT_DATA_ERROR = 'BUISNESS_UNIT_EXPORT_DATA_ERROR',

  BUISNESS_UNIT_EDIT_RECORD = 'BUISNESS_UNIT_EDIT_RECORD',
  BUISNESS_UNIT_EDIT_RECORD_SUCCESS = 'BUISNESS_UNIT_EDIT_RECORD_SUCCESS',
  BUISNESS_UNIT_EDIT_RECORD_ERROR = 'BUISNESS_UNIT_EDIT_RECORD_ERROR',

  BUISNESS_UNIT_DELETE_RECORD = 'BUISNESS_UNIT_DELETE_RECORD',
  BUISNESS_UNIT_DELETE_RECORD_SUCCESS = 'BUISNESS_UNIT_DELETE_RECORD_SUCCESS',
  BUISNESS_UNIT_DELETE_RECORD_ERROR = 'BUISNESS_UNIT_DELETE_RECORD_ERROR',

  BUISNESS_UNIT_SORT_CHANGED = 'BUISNESS_UNIT_SORT_CHANGED',
  BUISNESS_UNIT_FILTER_CHANGED = 'BUISNESS_UNIT_FILTER_CHANGED',
  BUISNESS_UNIT_PAGE_CHANGED = 'BUISNESS_UNIT_PAGE_CHANGED',
  BUISNESS_UNIT_PAGE_SIZE_CHANGED = 'BUISNESS_UNIT_PAGE_SIZE_CHANGED',

  /////////////////////////////////////////////////COM/////////////////////////////////////////////////
  COM_LOAD_DATA = 'COM_LOAD_DATA',
  COM_LOAD_DATA_SUCCESS = 'COM_LOAD_DATA_SUCCESS',
  COM_LOAD_DATA_ERROR = 'COM_LOAD_DATA_ERROR',
  COM_EXPORT_DATA = 'COM_EXPORT_DATA',
  COM_EXPORT_DATA_SUCCESS = 'COM_EXPORT_DATA_SUCCESS',
  COM_EXPORT_DATA_ERROR = 'COM_EXPORT_DATA_ERROR',
  COM_EDIT_RECORD = 'COM_EDIT_RECORD',
  COM_EDIT_RECORD_SUCCESS = 'COM_EDIT_RECORD_SUCCESS',
  COM_EDIT_RECORD_ERROR = 'COM_EDIT_RECORD_ERROR',
  COM_ADD_RECORD = 'COM_ADD_RECORD',
  COM_ADD_RECORD_SUCCESS = 'COM_ADD_RECORD_SUCCESS',
  COM_ADD_RECORD_ERROR = 'COM_ADD_RECORD_ERROR',
  COM_DELETE_RECORD = 'COM_DELETE_RECORD',
  COM_DELETE_RECORD_SUCCESS = 'COM_DELETE_RECORD_SUCCESS',
  COM_DELETE_RECORD_ERROR = 'COM_DELETE_RECORD_ERROR',
  COM_SORT_CHANGED = 'COM_SORT_CHANGED',
  COM_FILTER_CHANGED = 'COM_FILTER_CHANGED',
  COM_PAGE_CHANGED = 'COM_PAGE_CHANGED',
  COM_PAGE_SIZE_CHANGED = 'COM_PAGE_SIZE_CHANGED',
  /////////////////////////////////invited user////////////////////////////////////
  INVITED_USERS_LOAD_DATA = 'INVITED_USERS_LOAD_DATA',
  INVITED_USERS_LOAD_DATA_SUCCESS = 'INVITED_USERS_LOAD_DATA_SUCCESS',
  INVITED_USERS_LOAD_DATA_ERROR = 'INVITED_USERS_LOAD_DATA_ERROR',

  INVITED_EXPORT_DATA = 'INVITED_EXPORT_DATA',
  INVITED_EXPORT_DATA_SUCCESS = 'INVITED_EXPORT_DATA_SUCCESS',
  INVITED_EXPORT_DATA_ERROR = 'INVITED_EXPORT_DATA_ERROR',

  INVITED_USER_DELETE_RECORD = 'INVITED_USER_DELETE_RECORD',
  INVITED_USER_DELETE_RECORD_SUCCESS = 'INVITED_USER_DELETE_RECORD_SUCCESS',
  INVITED_USER_DELETE_RECORD_ERROR = 'INVITED_USER_DELETE_RECORD_ERROR',

  INVITED_USER_EDIT_RECORD = 'INVITED_USER_EDIT_RECORD',
  INVITED_USER_EDIT_RECORD_SUCCESS = 'INVITED_USER_EDIT_RECORD_SUCCESS',
  INVITED_USER_EDIT_RECORD_ERROR = 'INVITED_USER_EDIT_RECORD_ERROR',

  INVITED_USER_ADD_RECORD = 'INVITED_USER_ADD_RECORD',
  INVITED_USER_ADD_RECORD_SUCCESS = 'INVITED_USER_ADD_RECORD_SUCCESS',
  INVITED_USER_ADD_RECORD_ERROR = 'INVITED_USER_ADD_RECORD_ERROR',

  INVITED_USER_RESEND_RECORD = 'INVITED_USER_RESEND_RECORD',
  INVITED_USER_RESEND_RECORD_SUCCESS = 'INVITED_USER_RESEND_RECORD_SUCCESS',
  INVITED_USER_RESEND_RECORD_ERROR = 'INVITED_USER_RESEND_RECORD_ERROR',

  GET_COMPANY_BY_TYPENAME = 'GET_COMPANY_BY_TYPENAME',
  GET_COMPANY_BY_TYPENAME_SUCCESS = 'GET_COMPANY_BY_TYPENAME_SUCCESS',
  GET_COMPANY_BY_TYPENAME_ERROR = 'GET_COMPANY_BY_TYPENAME_ERROR',

  GET_COMPANY_TYPE_List = 'GET_COMPANY_TYPE_List',
  GET_COMPANY_TYPE_List_SUCCESS = 'GET_COMPANY_TYPE_List_SUCCESS',
  GET_COMPANY_TYPE_List_ERROR = 'GET_COMPANY_TYPE_List_ERROR',

  GET_ROLE_List = 'GGET_ROLE_List',
  GET_ROLE_List_SUCCESS = 'GET_ROLE_List_SUCCESS',
  GET_ROLE_List_ERROR = 'GET_ROLE_List_ERROR',


  INVITED_SORT_CHANGED = 'INVITED_SORT_CHANGED',
  INVITED_FILTER_CHANGED = 'INVITED_FILTER_CHANGED',
  INVITED_PAGE_CHANGED = 'INVITED_PAGE_CHANGED',
  INVITED_PAGE_SIZE_CHANGED = 'INVITED_PAGE_SIZE_CHANGED',

  ///////////////////////////////Registered Users////////////////////////////////////////
  REGISTERED_USERS_LOAD_DATA = 'REGISTERED_USERS_LOAD_DATA',
  REGISTERED_USERS_LOAD_DATA_SUCCESS = 'REGISTERED_USERS_LOAD_DATA_SUCCESS',
  REGISTERED_USERS_LOAD_DATA_ERROR = 'REGISTERED_USERS_LOAD_DATA_ERROR',

  REGISTERED_USER_DELETE_RECORD = 'REGISTERED_USER_DELETE_RECORD',
  REGISTERED_USER_DELETE_RECORD_SUCCESS = 'REGISTERED_USER_DELETE_RECORD_SUCCESS',
  REGISTERED_USER_DELETE_RECORD_ERROR = 'REGISTERED_USER_DELETE_RECORD_ERROR',

  REGISTERED_USER_EDIT_RECORD = 'REGISTERED_USER_EDIT_RECORD',
  REGISTERED_USER_EDIT_RECORD_SUCCESS = 'REGISTERED_USER_EDIT_RECORD_SUCCESS',
  REGISTERED_USER_EDIT_RECORD_ERROR = 'REGISTERED_USER_EDIT_RECORD_ERROR',

  REGISTERED_SORT_CHANGED = 'REGISTERED_SORT_CHANGED',
  REGISTERED_FILTER_CHANGED = 'REGISTERED_FILTER_CHANGED',
  REGISTERED_PAGE_CHANGED = 'REGISTERED_PAGE_CHANGED',
  REGISTERED_PAGE_SIZE_CHANGED = 'REGISTERED_PAGE_SIZE_CHANGED',

  REGISTERED_EXPORT_DATA = 'REGISTERED_EXPORT_DATA',
  REGISTERED_EXPORT_DATA_SUCCESS = 'REGISTERED_EXPORT_DATA_SUCCESS',
  REGISTERED_EXPORT_DATA_ERROR = 'REGISTERED_EXPORT_DATA_ERROR',

  GET_USER_EMAIL = 'GET_USER_EMAIL',
  GET_USER_EMAIL_SUCCESS = 'GET_USER_EMAIL_SUCCESS',
  GET_USER_EMAIL_ERROR = 'GET_USER_EMAIL_ERROR',

  /////////////////////////////////////////////////MAN/////////////////////////////////////////////////
  MAN_LOAD_DATA = 'MAN_LOAD_DATA',
  MAN_LOAD_DATA_SUCCESS = 'MAN_LOAD_DATA_SUCCESS',
  MAN_LOAD_DATA_ERROR = 'MAN_LOAD_DATA_ERROR',
  MAN_EXPORT_DATA = 'MAN_EXPORT_DATA',
  MAN_EXPORT_DATA_SUCCESS = 'MAN_EXPORT_DATA_SUCCESS',
  MAN_EXPORT_DATA_ERROR = 'MAN_EXPORT_DATA_ERROR',
  MAN_EDIT_RECORD = 'MAN_EDIT_RECORD',
  MAN_EDIT_RECORD_SUCCESS = 'MAN_EDIT_RECORD_SUCCESS',
  MAN_EDIT_RECORD_ERROR = 'MAN_EDIT_RECORD_ERROR',
  MAN_DELETE_RECORD = 'MAN_DELETE_RECORD',
  MAN_DELETE_RECORD_SUCCESS = 'MAN_DELETE_RECORD_SUCCESS',
  MAN_DELETE_RECORD_ERROR = 'MAN_DELETE_RECORD_ERROR',
  MAN_SORT_CHANGED = 'MAN_SORT_CHANGED',
  MAN_FILTER_CHANGED = 'MAN_FILTER_CHANGED',
  MAN_PAGE_CHANGED = 'MAN_PAGE_CHANGED',
  MAN_PAGE_SIZE_CHANGED = 'MAN_PAGE_SIZE_CHANGED',
  MAN_ADD_RECORD = 'MAN_ADD_RECORD',
  MAN_ADD_RECORD_SUCCESS = 'MAN_ADD_RECORD_SUCCESS',
  MAN_ADD_RECORD_ERROR = 'MAN_ADD_RECORD_ERROR',

  /////////////////////////////////////////////DIS-RElation////////////////////////////////////////////
  DIS_LOAD_DATA = 'DIS_LOAD_DATA',
  DIS_LOAD_DATA_SUCCESS = 'DIS_LOAD_DATA_SUCCESS',
  DIS_LOAD_DATA_ERROR = 'DIS_LOAD_DATA_ERROR',
  DIS_SORT_CHANGED = 'DIS_SORT_CHANGED',
  DIS_FILTER_CHANGED = 'DIS_FILTER_CHANGED',
  DIS_PAGE_CHANGED = 'DIS_PAGE_CHANGED',
  DIS_EDIT_RECORD = 'DIS_EDIT_RECORD',
  DIS_EDIT_RECORD_SUCCESS = 'DIS_EDIT_RECORD_SUCCESS',
  DIS_EDIT_RECORD_ERROR = 'DIS_EDIT_RECORD_ERROR',
  DIS_DELETE_RECORD = 'DIS_DELETE_RECORD',
  DIS_DELETE_RECORD_SUCCESS = 'DIS_DELETE_RECORD_SUCCESS',
  DIS_DELETE_RECORD_ERROR = 'DIS_DELETE_RECORD_ERROR',
  DIS_EXPORT_DATA = 'DIS_EXPORT_DATA',
  DIS_EXPORT_DATA_SUCCESS = 'DIS_EXPORT_DATA_SUCCESS',
  DIS_EXPORT_DATA_ERROR = 'DIS_EXPORT_DATA_ERROR',
  DIS_ADD_RECORD = 'DIS_ADD_RECORD',
  DIS_ADD_RECORD_SUCCESS = 'DIS_ADD_RECORD_SUCCESS',
  DIS_ADD_RECORD_ERROR = 'DIS_ADD_RECORD_ERROR',

  ////////////////////////////////////////////MAN-DIS-RElation/////////////////////////////////////////
  MAN_DIS_PAGE_SIZE_CHANGED = 'MAN_DIS_PAGE_SIZE_CHANGED',
  MAN_DIS_LOAD_DATA = 'MAN_DIS_LOAD_DATA',
  MAN_DIS_LOAD_DATA_SUCCESS = 'MAN_DIS_LOAD_DATA_SUCCESS',
  MAN_DIS_LOAD_DATA_ERROR = 'MAN_DIS_LOAD_DATA_ERROR',
  MAN_DIS_SORT_CHANGED = 'MAN_DIS_SORT_CHANGED',
  MAN_DIS_FILTER_CHANGED = 'MAN_DIS_FILTER_CHANGED',
  MAN_DIS_PAGE_CHANGED = 'MAN_DIS_PAGE_CHANGED',
  MAN_DIS_EDIT_RECORD = 'MAN_DIS_EDIT_RECORD',
  MAN_DIS_EDIT_RECORD_SUCCESS = 'MAN_DIS_EDIT_RECORD_SUCCESS',
  MAN_DIS_EDIT_RECORD_ERROR = 'MAN_DIS_EDIT_RECORD_ERROR',
  MAN_DIS_DELETE_RECORD = 'MAN_DIS_DELETE_RECORD',
  MAN_DIS_DELETE_RECORD_SUCCESS = 'MAN_DIS_DELETE_RECORD_SUCCESS',
  MAN_DIS_DELETE_RECORD_ERROR = 'MAN_DIS_DELETE_RECORD_ERROR',
  MAN_DIS_EXPORT_DATA = 'MAN_DIS_EXPORT_DATA',
  MAN_DIS_EXPORT_DATA_SUCCESS = 'MAN_DIS_EXPORT_DATA_SUCCESS',
  MAN_DIS_EXPORT_DATA_ERROR = 'MAN_DIS_EXPORT_DATA_ERROR',
  MAN_DIS_RECORD = 'MAN_DIS_RECORD',
  MAN_DIS_RECORD_SUCCESS = 'MAN_DIS_RECORD_SUCCESS',
  MAN_DIS_RECORD_ERROR = 'MAN_DIS_RECORD_ERROR',
  MAN_DIS_ADD_RECORD = 'MAN_DIS_ADD_RECORD',
  MAN_DIS_ADD_RECORD_SUCCESS = 'MAN_DIS_ADD_RECORD_SUCCESS',
  MAN_DIS_ADD_RECORD_ERROR = 'MAN_DIS_ADD_RECORD_ERROR',

  //////////////////////////////////////Year-Currency/////////////////////////////////////////////
  YEAR_EDIT_RECORD = 'YEAR_EDIT_RECORD',
  YEAR_EDIT_RECORD_SUCCESS = 'YEAR_EDIT_RECORD_SUCCESS',
  YEAR_EDIT_RECORD_ERROR = 'YEAR_EDIT_RECORD_ERROR',
  CURRENCY_EDIT_RECORD = 'CURRENCY_EDIT_RECORD',
  CURRENCY_EDIT_RECORD_SUCCESS = 'CURRENCY_EDIT_RECORD_SUCCESS',
  CURRENCY_EDIT_RECORD_ERROR = 'CURRENCY_EDIT_RECORD_ERROR',
  EXCHANGE_LOAD_DATA = 'EXCHANGE_LOAD_DATA',
  EXCHANGE_LOAD_DATA_SUCCESS = 'EXCHANGE_LOAD_DATA_SUCCESS',
  EXCHANGE_LOAD_DATA_ERROR = 'EXCHANGE_LOAD_DATA_ERROR',
  EXCHANGE_DELETE_RECORD = 'EXCHANGE_DELETE_RECORD',
  EXCHANGE_DELETE_RECORD_SUCCESS = 'EXCHANGE_DELETE_RECORD_SUCCESS',
  EXCHANGE_DELETE_RECORD_ERROR = 'EXCHANGE_DELETE_RECORD_ERROR',
  EXCHANGE_EDIT_RECORD = 'EXCHANGE_EDIT_RECORD',
  EXCHANGE_EDIT_RECORD_SUCCESS = 'EXCHANGE_EDIT_RECORD_SUCCESS',
  EXCHANGE_EDIT_RECORD_ERROR = 'EXCHANGE_EDIT_RECORD_ERROR',
  EXCHANGE_PAGE_SIZE_CHANGED = 'EXCHANGE_PAGE_SIZE_CHANGED',

  //////////////////////////////////////CM/////////////////////////////////////////////
  CM_LOAD_DATA = 'CM_LOAD_DATA',
  CM_LOAD_DATA_SUCCESS = 'CM_LOAD_DATA_SUCCESS',
  CM_LOAD_DATA_ERROR = 'CM_LOAD_DATA_ERROR',
  CM_EXPORT_DATA = 'CM_EXPORT_DATA',
  CM_EXPORT_DATA_SUCCESS = 'CM_EXPORT_DATA_SUCCESS',
  CM_EXPORT_DATA_ERROR = 'CM_EXPORT_DATA_ERROR',
  CM_EDIT_RECORD = 'CM_EDIT_RECORD',
  CM_EDIT_RECORD_SUCCESS = 'CM_EDIT_RECORD_SUCCESS',
  CM_EDIT_RECORD_ERROR = 'CM_EDIT_RECORD_ERROR',
  CM_DELETE_RECORD = 'CM_DELETE_RECORD',
  CM_DELETE_RECORD_SUCCESS = 'CM_DELETE_RECORD_SUCCESS',
  CM_DELETE_RECORD_ERROR = 'CM_DELETE_RECORD_ERROR',
  CM_SORT_CHANGED = 'CM_SORT_CHANGED',
  CM_FILTER_CHANGED = 'CM_FILTER_CHANGED',
  CM_PAGE_CHANGED = 'CM_PAGE_CHANGED',
  CM_ADD_RECORD = 'CM_ADD_RECORD',
  CM_ADD_RECORD_SUCCESS = 'CM_ADD_RECORD_SUCCESS',
  CM_ADD_RECORD_ERROR = 'CM_ADD_RECORD_ERROR',
  CM_PAGE_SIZE_CHANGED = 'CM_PAGE_SIZE_CHANGED',




  ////////////////////////////////////cm site/////////////////////
  CMSite_EXPORT_DATA = 'CMSite_EXPORT_DATA',
  CMSite_EXPORT_DATA_SUCCESS = 'CMSite_EXPORT_DATA_SUCCESS',
  CMSite_EXPORT_DATA_ERROR = 'CMSiteEXPORT_DATA_ERROR',

  //////////////////////////////////dropdownlists///////////////////////////////////////////////
  GET_MAN_List = 'GET_MAN_List',
  GET_MAN_List_SUCCESS = 'GET_MAN_List_SUCCESS',
  GET_MAN_List_ERROR = 'GET_MAN_List_ERROR',

  GET_DIS_List = 'GET_DIS_List',
  GET_DIS_List_SUCCESS = 'GET_DIS_List_SUCCESS',
  GET_DIS_List_ERROR = 'GET_DIS_List_ERROR',

  GET_COMM_List = 'GET_COMM_List',
  GET_COMM_List_SUCCESS = 'GET_COMM_List_SUCCESS',
  GET_COMM_List_ERROR = 'GET_COMM_List_ERROR',

  GET_COMM_OWNERS_List = 'GET_COMM_OWNERS_List',
  GET_COMM_OWNERS_List_SUCCESS = 'GET_COMM_OWNERS_List_SUCCESS',
  GET_COMM_OWNERS_List_ERROR = 'GET_COMM_OWNERS_List_ERROR',

  GET_STATUS_List = 'GET_STATUS_List',
  GET_STATUS_List_SUCCESS = 'GET_STATUS_List_SUCCESS',
  GET_STATUS_List_ERROR = 'GET_STATUS_List_ERROR',

  GET_TYPE_List = 'GET_TYPE_List',
  GET_TYPE_List_SUCCESS = 'GET_TYPE_List_SUCCESS',
  GET_TYPE_List_ERROR = 'GET_TYPE_List_ERROR',
  MAPPED_GRID_DATA = 'MAPPED_GRID_DATA',
  ///////////////////////////////LOA////////////////////////////////////////
  LOA_GRID_LOAD_DATA = 'LOA_GRID_LOAD_DATA',
  LOA_GRID_LOAD_DATA_SUCCESS = 'LOA_GRID_LOAD_DATA_SUCCESS',
  LOA_GRID_LOAD_DATA_ERROR = 'LOA_GRID_LOAD_DATA_ERROR',
  LOA_SORT_CHANGED = 'LOA_SORT_CHANGED',
  LOA_PAGE_CHANGED = 'LOA_PAGE_CHANGED',
  LOA_FILTER_CHANGED = 'LOA_FILTER_CHANGED',
  LOA_PAGE_SIZE_CHANGED = 'LOA_PAGE_SIZE_CHANGED',
  SHARE_LOA = 'SHARE_LOA',
  EMPTY_SHARE_LOA = 'EMPTY_SHARE_LOA',
  SHARE_LOA_SUCCESS = 'SHARE_LOA_SUCCESS',
  SHARE_LOA_ERROR = 'SHARE_LOA_ERROR',
  EXPORT_LOA_PDF = 'EXPORT_LOA_PDF',
  EXPORT_LOA_PDF_SUCCESS = 'EXPORT_LOA_PDF_SUCCESS',
  EXPORT_LOA_PDF_ERROR = 'EXPORT_LOA_PDF_ERROR',

  ////////////////////////////////MASSIVE UPLOAD////////////////////////////////////
  SET_CURRENT_ACTION_DATA = "SET_CURRENT_ACTION_DATA",

  CHECK_IS_ACTIVE_FILE = "CHECK_IS_ACTIVE_FILE",
  CHECK_IS_ACTIVE_FILE_SUCCESS = "CHECK_IS_ACTIVE_FILE_SUCCESS",
  CHECK_IS_ACTIVE_FILE_ERROR = "CHECK_IS_ACTIVE_FILE_ERROR",
  EMPTY_IS_ACTIVE_FILE_STATUS = "EMPTY_IS_ACTIVE_FILE_STATUS",

  EMPTY_UPLOADED_ACTIVE_FILE_STATUS = "EMPTY_UPLOADED_ACTIVE_FILE_STATUS",


  DISCARD_ACTIVE_FILE = "DISCARD_ACTIVE_FILE",
  DISCARD_ACTIVE_FILE_SUCCESS = "DISCARD_ACTIVE_FILE_SUCCESS",
  DISCARD_ACTIVE_FILE_ERROR = "DISCARD_ACTIVE_FILE_ERROR",

  START_UPLOAD_FILE = "START_UPLOAD_FILE",
  START_UPLOAD_FILE_SUCCESS = "START_UPLOAD_FILE_SUCCESS",
  START_UPLOAD_FILE_ERROR = "START_UPLOAD_FILE_ERROR",

  MAP_UPLOADED_FILE = "MAP_UPLOADED_FILE",
  MAP_UPLOADED_FILE_SUCCESS = "MAP_UPLOADED_FILE_SUCCESS",
  MAP_UPLOADED_FILE_ERROR = "MAP_UPLOADED_FILE_ERROR",

  VALIDATE_UPLOADED_FILE = "VALIDATE_UPLOADED_FILE",
  VALIDATE_UPLOADED_FILE_SUCCESS = "VALIDATE_UPLOADED_FILE_SUCCESS",
  VALIDATE_UPLOADED_FILE_ERROR = "VALIDATE_UPLOADED_FILE_ERROR",

  EXPORT_VALIDATED_UPLOADED_FILE = "EXPORT_VALIDATED_UPLOADED_FILE",
  EXPORT_VALIDATED_UPLOADED_FILE_SUCCESS = "EXPORT_VALIDATED_UPLOADED_FILE_SUCCESS",
  EXPORT_VALIDATED_UPLOADED_FILE_ERROR = "EXPORT_VALIDATED_UPLOADED_FILE_ERROR",

  SUBMIT_UPLOADED_FILE = "SUBMIT_UPLOADED_FILE",
  SUBMIT_UPLOADED_FILE_SUCCESS = "SUBMIT_UPLOADED_FILE_SUCCESS",
  SUBMIT_UPLOADED_FILE_ERROR = "SUBMIT_UPLOADED_FILE_ERROR",

  ///////////////////////////////////////GRID ACTIONS////////////////////////////////////////
  LOAD_DATA_Grid = 'LOAD_DATA_Grid',
  LOAD_DATA_Grid_SUCCESS = 'LOAD_DATA_Grid_SUCCESS',
  LOAD_DATA_Grid_ERROR = 'LOAD_DATA_Grid_ERROR',

  // record actions names
  RECORD_SORT_CHANGED = 'RECORD_SORT_CHANGED',
  RECORD_FILTER_CHANGED = 'RECORD_FILTER_CHANGED',
  RECORD_PAGE_CHANGED = 'RECORD_PAGE_CHANGED',
  RECORD_PAGE_SIZE_CHANGED = 'RECORD_PAGE_SIZE_CHANGED',
  // delete grid record
  DELETE_RECORD = 'DELETE_RECORD',
  DELETE_RECORD_SUCCESS = 'DELETE_RECORD_SUCCESS',
  DELETE_RECORD_ERROR = 'DELETE_RECORD_ERROR',
  // add/edit grid record
  ADD_EDIT_RECORD = 'ADD_EDIT_RECORD',
  ADD_EDIT_RECORD_SUCCESS = 'ADD_EDIT_RECORD_SUCCESS',
  ADD_EDIT_RECORD_ERROR = 'ADD_EDIT_RECORD_ERROR',

  ADD_EDIT_COMMODITY = 'ADD_EDIT_COMMODITY',
  ADD_EDIT_COMMODITY_SUCCESS = 'ADD_EDIT_COMMODITY_SUCCESS',
  ADD_EDIT_COMMODITY_ERROR = 'ADD_EDIT_COMMODITY_ERROR',


  Customer_Commodity_EXPORT_DATA = 'Customer_Commodity_EXPORT_DATA',
  Customer_Commodity_EXPORT_DATA_SUCCESS = 'Customer_Commodity_EXPORT_DATA_SUCCESS',
  Customer_Commodity_EXPORT_DATA_ERROR = 'Customer_Commodity_EXPORT_DATA_ERROR',

  LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS",
  LOG_OUT_ERROR = "LOG_OUT_ERROR"
}

export class SetValidationError implements Action {
  readonly type = ActionTypes.SET_VALIDATION_ERROR;
  constructor(public validationError?: string) {}
}

//-----------------------Division_EXPORT_DATA action-------------------------------------
export class CustomerSiteExportGrid implements Action {
  readonly type = ActionTypes.CustomerSite_EXPORT_DATA;
  constructor( public payload?:any){}
}

export class CustomerSiteExportGridSucees implements Action {
  readonly type = ActionTypes.CustomerSite_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) {}
}

export class CustomerSiteExportGridError implements Action {
  readonly type = ActionTypes.CustomerSite_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}
//////////////////////////////////////////TICKET/////////////////////////////////

// GET TICKET ASSIGNEE
export class GetTicketAssigne implements Action {
  readonly type = ActionTypes.GET_TICKET_ASSIGNEE;
  constructor() {}
}

export class GetTicketAssigneSucees implements Action {
  readonly type = ActionTypes.GET_TICKET_ASSIGNEE_SUCCESS;
  constructor(public payload: any) {}
}

export class GetTicketAssigneError implements Action {
  readonly type = ActionTypes.GET_TICKET_ASSIGNEE_ERROR;
  constructor(public payload: any) {}
}
// GET TICKET YEAR
export class GetTicketYear implements Action {
  readonly type = ActionTypes.GET_TICKET_YEAR;
  constructor() {}
}

export class GetTicketYearSucees implements Action {
  readonly type = ActionTypes.GET_TICKET_YEAR_SUCCESS;
  constructor(public payload: any) {}
}

export class GetTicketYearError implements Action {
  readonly type = ActionTypes.GET_TICKET_YEAR_ERROR;
  constructor(public payload: any) {}
}
//GET TICKET TYPE

export class GetTicketType implements Action {
  readonly type = ActionTypes.GET_TICKET_TYPE;
  constructor() {}
}

export class GetTicketTypeSucees implements Action {
  readonly type = ActionTypes.GET_TICKET_TYPE_SUCCESS;
  constructor(public payload: any) {}
}

export class GetTicketTypeError implements Action {
  readonly type = ActionTypes.GET_TICKET_TYPE_ERROR;
  constructor(public payload: any) {}
}

export class TicketLoadData implements Action {
  readonly type = ActionTypes.TICKET_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class TicketLoadDataSucees implements Action {
  readonly type = ActionTypes.TICKET_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class TicketLoadDataError implements Action {
  readonly type = ActionTypes.TICKET_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

// GET TICKET RECORD
export class TicketLoadDataRecord implements Action {
  readonly type = ActionTypes.TICKET_LOAD_DATA_RECORD;
  constructor(public TicketID: number) {}
}

export class TicketLoadDataRecordSucees implements Action {
  readonly type = ActionTypes.TICKET_LOAD_DATA_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class TicketLoadDataRecordError implements Action {
  readonly type = ActionTypes.TICKET_LOAD_DATA_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class TicketExportGrid implements Action {
  readonly type = ActionTypes.TICKET_EXPORT_DATA;
  constructor() {}
}

export class TicketExportGridSucees implements Action {
  readonly type = ActionTypes.TICKET_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) {}
}

export class TicketExportGridError implements Action {
  readonly type = ActionTypes.TICKET_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class TicketEdit implements Action {
  readonly type = ActionTypes.TICKET_EDIT_RECORD;
  constructor(public payload: any) {}
}

export class TicketEditSuccess implements Action {
  readonly type = ActionTypes.TICKET_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class TicketEditError implements Action {
  readonly type = ActionTypes.TICKET_EDIT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class TicketDelete implements Action {
  readonly type = ActionTypes.TICKET_DELETE_RECORD;
  constructor(public TicketID: number) {}
}

export class TicketDeleteSuccess implements Action {
  readonly type = ActionTypes.TICKET_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class TicketDeleteError implements Action {
  readonly type = ActionTypes.TICKET_DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}

//approve
export class TicketApprove implements Action {
  readonly type = ActionTypes.TICKET_APPROVE_RECORD;
  constructor(public TicketID: number) {}
}

export class TicketApproveSuccess implements Action {
  readonly type = ActionTypes.TICKET_APPROVE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class TicketApproveError implements Action {
  readonly type = ActionTypes.TICKET_APPROVE_RECORD_ERROR;
  constructor(public payload: any) {}
}

//REJECT
export class TicketReject implements Action {
  readonly type = ActionTypes.TICKET_REJECT_RECORD;
  constructor(public TicketID: number) {}
}

export class TicketRejectSuccess implements Action {
  readonly type = ActionTypes.TICKET_REJECT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class TicketRejectError implements Action {
  readonly type = ActionTypes.TICKET_REJECT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class TicketPageChanged implements Action {
  readonly type = ActionTypes.TICKET_PAGE_CHANGED;
  constructor(public page?: number) {}
}

export class TicketSortChanged implements Action {
  readonly type = ActionTypes.TICKET_SORT_CHANGED;
  constructor(public sort?: SortDescriptor[]) {}
}

export class TicketFilterChanged implements Action {
  readonly type = ActionTypes.TICKET_FILTER_CHANGED;
  constructor(public filter?: FilterDescriptor[]) {
  }
}

export class TicketPageSizeChanged implements Action {
  readonly type = ActionTypes.TICKET_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

export class ReviewTicketLoadData implements Action {
  readonly type = ActionTypes.REVIEW_TICKET_LOAD_DATA;
  constructor(public ticketId: number) {}
}

export class ReviewTicketLoadDataSucees implements Action {
  readonly type = ActionTypes.REVIEW_TICKET_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class ReviewTicketLoadDataError implements Action {
  readonly type = ActionTypes.REVIEW_TICKET_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class ReviewTicketApprove implements Action {
  readonly type = ActionTypes.REVIEW_TICKET_APPROVE;
  constructor(public ticketId: number) {}
}

export class ReviewTicketApproveSucees implements Action {
  readonly type = ActionTypes.REVIEW_TICKET_APPROVE_SUCCESS;
  constructor(public payload: any) {}
}

export class ReviewTicketApproveError implements Action {
  readonly type = ActionTypes.REVIEW_TICKET_APPROVE_ERROR;
  constructor(public payload: any) {}
}

export class ReviewTicketReject implements Action {
  readonly type = ActionTypes.REVIEW_TICKET_REJECT;
  constructor(public ticketId: number) {}
}

export class ReviewTicketRejectSucees implements Action {
  readonly type = ActionTypes.REVIEW_TICKET_REJECT_SUCCESS;
  constructor(public payload: any) {}
}

export class ReviewTicketRejectError implements Action {
  readonly type = ActionTypes.REVIEW_TICKET_REJECT_ERROR;
  constructor(public payload: any) {}
}

export class ExportComponentData implements Action {
  readonly type = ActionTypes.EXPORT_COMPONENT_DATA;
  constructor(
    public ticketId?: any,
    public option?: any,
    public fileName?: any
  ) {}
}
export class ExportComponentDataSucees implements Action {
  readonly type = ActionTypes.EXPORT_COMPONENT_DATA_SUCCESS;

  constructor(public payload: any, public fileName: any) {}
}

export class ExportComponentDataError implements Action {
  readonly type = ActionTypes.EXPORT_COMPONENT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class ExportTicketReview implements Action {
  readonly type = ActionTypes.EXPORT_TICKET_REVIEW;
  constructor(public ticketId?: any, public fileName?: any) {}
}

export class ExportTicketReviewSucees implements Action {
  readonly type = ActionTypes.EXPORT_TICKET_REVIEW_SUCCESS;
  constructor(public payload: any, public fileName: any) {}
}

export class ExportTicketReviewError implements Action {
  readonly type = ActionTypes.EXPORT_TICKET_REVIEW_ERROR;
  constructor(public payload: any) {}
}
//////////////////////////////////////////BUISNESS UNIT/////////////////////////////////

export class BuisnessUnitLoadData implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class BuisnessUnitLoadDataSucees implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class BuisnessUnitLoadDataError implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class BuisnessUnitExportGrid implements Action {
  // public APIURL = `${environment.baseUrls.serviceAdmin}/businessunits/export`;

  readonly type = ActionTypes.BUISNESS_UNIT_EXPORT_DATA;
  constructor( public payload?:any) {
  }
}

export class BuisnessUnitExportGridSucees implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) {}
}

export class BuisnessUnitExportGridError implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class BuisnessUnitEdit implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_EDIT_RECORD;
  constructor(public payload: any) {}
}

export class BuisnessUnitEditSuccess implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class BuisnessUnitEditError implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_EDIT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class BuisnessUnitDelete implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_DELETE_RECORD;
  constructor(public BuisnessUnitID: number) {}
}

export class BuisnessUnitDeleteSuccess implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class BuisnessUnitDeleteError implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class BuisnessUnitPageChanged implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_PAGE_CHANGED;
  constructor(public page?: number) {}
}

export class BuisnessUnitSortChanged implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_SORT_CHANGED;
  constructor(public sort?: SortDescriptor) {}
}

export class BuisnessUnitFilterChanged implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_FILTER_CHANGED;
  constructor(public filter?: FilterDescriptor[]) {}
}

export class BuisnessUnitPageSizeChanged implements Action {
  readonly type = ActionTypes.BUISNESS_UNIT_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

//////////////////////////////////////////COM/////////////////////////////////

export class ComLoadData implements Action {
  readonly type = ActionTypes.COM_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class ComLoadDataSucees implements Action {
  readonly type = ActionTypes.COM_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class ComLoadDataError implements Action {
  readonly type = ActionTypes.COM_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class ComExportGrid implements Action {
  readonly type = ActionTypes.COM_EXPORT_DATA;
  constructor(public payload?: any) {}
}

export class ComExportGridSucees implements Action {
  readonly type = ActionTypes.COM_EXPORT_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class ComExportGridError implements Action {
  readonly type = ActionTypes.COM_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class ComEdit implements Action {
  readonly type = ActionTypes.COM_EDIT_RECORD;
  constructor(public payload: any) {}
}

export class ComEditSuccess implements Action {
  readonly type = ActionTypes.COM_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class ComEditError implements Action {
  readonly type = ActionTypes.COM_EDIT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class ComDelete implements Action {
  readonly type = ActionTypes.COM_DELETE_RECORD;
  constructor(public comID: number) {}
}

export class ComDeleteSuccess implements Action {
  readonly type = ActionTypes.COM_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class ComDeleteError implements Action {
  readonly type = ActionTypes.COM_DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class ComPageChanged implements Action {
  readonly type = ActionTypes.COM_PAGE_CHANGED;
  constructor(public page?: number) {}
}

export class ComSortChanged implements Action {
  readonly type = ActionTypes.COM_SORT_CHANGED;
  constructor(public sort?: SortDescriptor[]) {}
}

export class ComFilterChanged implements Action {
  readonly type = ActionTypes.COM_FILTER_CHANGED;
  constructor(public filter?: FilterDescriptor[]) {}
}

export class ComPageSizeChanged implements Action {
  readonly type = ActionTypes.COM_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

//////////////////////////////////////////MAN/////////////////////////////////

export class ManLoadData implements Action {
  readonly type = ActionTypes.MAN_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class ManLoadDataSucees implements Action {
  readonly type = ActionTypes.MAN_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class ManLoadDataError implements Action {
  readonly type = ActionTypes.MAN_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class ManExportGrid implements Action {
  readonly type = ActionTypes.MAN_EXPORT_DATA;
  constructor(public payload?: any) {}
}

export class ManExportGridSucees implements Action {
  readonly type = ActionTypes.MAN_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) {}
}

export class ManExportGridError implements Action {
  readonly type = ActionTypes.MAN_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class ManEdit implements Action {
  readonly type = ActionTypes.MAN_EDIT_RECORD;
  constructor(public payload: any) {}
}

export class ManEditSuccess implements Action {
  readonly type = ActionTypes.MAN_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class ManEditError implements Action {
  readonly type = ActionTypes.MAN_EDIT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class ManDelete implements Action {
  readonly type = ActionTypes.MAN_DELETE_RECORD;
  constructor(public manID: number) {}
}

export class ManDeleteSuccess implements Action {
  readonly type = ActionTypes.MAN_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class ManDeleteError implements Action {
  readonly type = ActionTypes.MAN_DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class ManPageChanged implements Action {
  readonly type = ActionTypes.MAN_PAGE_CHANGED;
  constructor(public page?: number) {}
}

export class ManPageSizeChanged implements Action {
  readonly type = ActionTypes.MAN_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

export class ManSortChanged implements Action {
  readonly type = ActionTypes.MAN_SORT_CHANGED;
  constructor(public sort?: SortDescriptor[]) {}
}

export class ManFilterChanged implements Action {
  readonly type = ActionTypes.MAN_FILTER_CHANGED;
  constructor(public filter?: FilterDescriptor[]) {}
}
///////////////////////INVITED /////////////////////////////

export class GetCompanyByTypeName implements Action {
  readonly type = ActionTypes.GET_COMPANY_BY_TYPENAME;
  constructor(public companyTypeId: number, public companyName: string) {}
}

export class GetCompanyByTypeNameSuccess implements Action {
  readonly type = ActionTypes.GET_COMPANY_BY_TYPENAME_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCompanyByTypeNameError implements Action {
  readonly type = ActionTypes.GET_COMPANY_BY_TYPENAME_ERROR;
  constructor(public payload: any) {}
}

export class InvitedUserDelete implements Action {
  readonly type = ActionTypes.INVITED_USER_DELETE_RECORD;
  constructor(public userID: number) {}
}

export class InvitedUserDeleteSuccess implements Action {
  readonly type = ActionTypes.INVITED_USER_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class InvitedUserDeleteError implements Action {
  readonly type = ActionTypes.INVITED_USER_DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}
export class InvitedEdit implements Action {
  readonly type = ActionTypes.INVITED_USER_EDIT_RECORD;
  constructor(public payload: any) {}
}

export class InvitedEditSuccess implements Action {
  readonly type = ActionTypes.INVITED_USER_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class InvitedEditError implements Action {
  readonly type = ActionTypes.INVITED_USER_EDIT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class InvitedAdd implements Action {
  readonly type = ActionTypes.INVITED_USER_ADD_RECORD;
  constructor(public payload: any) {}
}

export class InvitedAddSuccess implements Action {
  readonly type = ActionTypes.INVITED_USER_ADD_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class InvitedAddError implements Action {
  readonly type = ActionTypes.INVITED_USER_ADD_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class InvitedResend implements Action {
  readonly type = ActionTypes.INVITED_USER_RESEND_RECORD;
  constructor(public invitationId: any) {}
}

export class InvitedResendSuccess implements Action {
  readonly type = ActionTypes.INVITED_USER_RESEND_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class InvitedResendError implements Action {
  readonly type = ActionTypes.INVITED_USER_RESEND_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class GetCompanyTypeList implements Action {
  readonly type = ActionTypes.GET_COMPANY_TYPE_List;
  constructor() {}
}

export class GetCompanyTypeListSuccess implements Action {
  readonly type = ActionTypes.GET_COMPANY_TYPE_List_SUCCESS;
  constructor(public payload: IManufacturerStatus[]) {}
}

export class GetCompanyTypeListError implements Action {
  readonly type = ActionTypes.GET_COMPANY_TYPE_List_ERROR;
  constructor(public payload: any) {}
}

export class GetRoleList implements Action {
  readonly type = ActionTypes.GET_ROLE_List;
  constructor(public companyTypeName: string) {}
}

export class GetRoleListSuccess implements Action {
  readonly type = ActionTypes.GET_ROLE_List_SUCCESS;
  constructor(public payload: IManufacturerStatus[]) {}
}

export class GetRoleListError implements Action {
  readonly type = ActionTypes.GET_ROLE_List_ERROR;
  constructor(public payload: any) {}
}

export class InvitedExportGrid implements Action {
  readonly type = ActionTypes.INVITED_EXPORT_DATA;
  constructor(public payload?: any) {}
}

export class InvitedExportGridSucees implements Action {
  readonly type = ActionTypes.INVITED_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) {}
}

export class InvitedExportGridError implements Action {
  readonly type = ActionTypes.INVITED_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}


export class InvitedUsersLoadData implements Action {
  readonly type = ActionTypes.INVITED_USERS_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class InvitedUsersLoadDataSucees implements Action {
  readonly type = ActionTypes.INVITED_USERS_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class InvitedUsersLoadDataError implements Action {
  readonly type = ActionTypes.INVITED_USERS_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}
export class InvitedPageChanged implements Action {
  readonly type = ActionTypes.INVITED_PAGE_CHANGED;
  constructor(public page?: number) {}
}

export class InvitedPageSizeChanged implements Action {
  readonly type = ActionTypes.INVITED_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

export class InvitedSortChanged implements Action {
  readonly type = ActionTypes.INVITED_SORT_CHANGED;
  constructor(public sort?: SortDescriptor[]) {}
}

export class InvitedFilterChanged implements Action {
  readonly type = ActionTypes.INVITED_FILTER_CHANGED;
  constructor(public filter?: FilterDescriptor[]) {}
}

/////////////////////////Registered Users////////////////////////////////////////
export class RegisteredUserDelete implements Action {
  readonly type = ActionTypes.REGISTERED_USER_DELETE_RECORD;
  constructor(public userID: number) {}
}

export class RegisteredUserDeleteSuccess implements Action {
  readonly type = ActionTypes.REGISTERED_USER_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class RegisteredUserDeleteError implements Action {
  readonly type = ActionTypes.REGISTERED_USER_DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}
export class RegisteredUserEdit implements Action {
  readonly type = ActionTypes.REGISTERED_USER_EDIT_RECORD;
  constructor(public payload: IRegisteredUser) {}
}

export class RegisteredUserEditSuccess implements Action {
  readonly type = ActionTypes.REGISTERED_USER_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class RegisteredUserEditError implements Action {
  readonly type = ActionTypes.REGISTERED_USER_EDIT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class RegisteredPageSizeChanged implements Action {
  readonly type = ActionTypes.REGISTERED_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

export class RegisteredSortChanged implements Action {
  readonly type = ActionTypes.REGISTERED_SORT_CHANGED;
  constructor(public sort?: SortDescriptor[]) {}
}

export class RegisteredFilterChanged implements Action {
  readonly type = ActionTypes.REGISTERED_FILTER_CHANGED;
  constructor(public filter?: FilterDescriptor[]) {}
}

export class RegisteredLoadData implements Action {
  readonly type = ActionTypes.REGISTERED_USERS_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class RegisteredLoadDataSucees implements Action {
  readonly type = ActionTypes.REGISTERED_USERS_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class RegisteredLoadDataError implements Action {
  readonly type = ActionTypes.REGISTERED_USERS_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}
export class RegisteredPageChanged implements Action {
  readonly type = ActionTypes.REGISTERED_PAGE_CHANGED;
  constructor(public page?: number) {}
}
export class RegisteredExportGrid implements Action {
  readonly type = ActionTypes.REGISTERED_EXPORT_DATA;
  constructor(public payload?: any) {}
}

export class RegisteredExportGridSucees implements Action {
  readonly type = ActionTypes.REGISTERED_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) {}
}

export class RegisteredExportGridError implements Action {
  readonly type = ActionTypes.REGISTERED_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class GetUserEmailByInvitationCode implements Action {
  readonly type = ActionTypes.GET_USER_EMAIL;
  constructor(public InvitationCode: any) {}
}

export class GetUserEmailByInvitationCodeSuccess implements Action {
  readonly type = ActionTypes.GET_USER_EMAIL_SUCCESS;
  constructor(public payload: ICommodity[]) {}
}

export class GetUserEmailByInvitationCodeError implements Action {
  readonly type = ActionTypes.GET_USER_EMAIL_ERROR;
  constructor(public payload: any) {}
}
/////////////////////// distributer/////////////////////////////////////////////////////////////////////////
export class DisLoadData implements Action {
  readonly type = ActionTypes.DIS_LOAD_DATA;

  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class DisLoadDataSucees implements Action {
  readonly type = ActionTypes.DIS_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class DisLoadDataError implements Action {
  readonly type = ActionTypes.DIS_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class DisPageChanged implements Action {
  readonly type = ActionTypes.DIS_PAGE_CHANGED;
  constructor(public page?: number) {}
}

export class DisSortChanged implements Action {
  readonly type = ActionTypes.DIS_SORT_CHANGED;
  constructor(public sort?: ISortModel) {}
}

export class DisFilterChanged implements Action {
  readonly type = ActionTypes.DIS_FILTER_CHANGED;
  constructor(public filter?: IFilterModel) {}
}

export class DisEdit implements Action {
  readonly type = ActionTypes.DIS_EDIT_RECORD;
  constructor(public payload: any) {}
}

export class DisEditSuccess implements Action {
  readonly type = ActionTypes.DIS_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class DisEditError implements Action {
  readonly type = ActionTypes.DIS_EDIT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class DisAdd implements Action {
  readonly type = ActionTypes.DIS_ADD_RECORD;
  constructor(public payload: any) {}
}

export class DisAddSuccess implements Action {
  readonly type = ActionTypes.DIS_ADD_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class DisAddError implements Action {
  readonly type = ActionTypes.DIS_ADD_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class DisDelete implements Action {
  readonly type = ActionTypes.DIS_DELETE_RECORD;
  constructor(public disID: number) {}
}

export class DisDeleteSuccess implements Action {
  readonly type = ActionTypes.DIS_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class DisDeleteError implements Action {
  readonly type = ActionTypes.DIS_DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}
//-------------------------DivisionExportGrid------------------

export class DivisionsExportGrid implements Action {
  readonly type = ActionTypes.Divisions_EXPORT_DATA;
  constructor(public payload?: any) {}
}

export class DivisionsExportGridSucees implements Action {
  readonly type = ActionTypes.Divisions_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) {}
}

export class DivisionsExportGridError implements Action {
  readonly type = ActionTypes.Divisions_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}
export class DisExportGrid implements Action {
  readonly type = ActionTypes.DIS_EXPORT_DATA;
  constructor(public payload?: any) {}

}

export class DisExportGridSucees implements Action {
  readonly type = ActionTypes.DIS_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) {}
}

export class DisExportGridError implements Action {
  readonly type = ActionTypes.DIS_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

//////////////////////////////// manufaturer-distributer/////////////////////////////////////////////
export class ManDisLoadData implements Action {
  readonly type = ActionTypes.MAN_DIS_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class ManDisPageSizeChanged implements Action {
  readonly type = ActionTypes.MAN_DIS_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

export class ManDisLoadDataSucees implements Action {
  readonly type = ActionTypes.MAN_DIS_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class ManDisLoadDataError implements Action {
  readonly type = ActionTypes.MAN_DIS_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class ManDisPageChanged implements Action {
  readonly type = ActionTypes.MAN_DIS_PAGE_CHANGED;
  constructor(public page?: number) {}
}

export class ManDisSortChanged implements Action {
  readonly type = ActionTypes.MAN_DIS_SORT_CHANGED;
  constructor(public sort?: ISortModel) {}
}

export class ManDisFilterChanged implements Action {
  readonly type = ActionTypes.MAN_DIS_FILTER_CHANGED;
  constructor(public filter?: IFilterModel) {}
}

export class ManDisEdit implements Action {
  readonly type = ActionTypes.MAN_DIS_EDIT_RECORD;
  constructor(public payload: manDisRequest) {}
}
export class ManDisEditSuccess implements Action {
  readonly type = ActionTypes.MAN_DIS_EDIT_RECORD_SUCCESS;
  constructor(public payload: IManufacturerDistributors) {}
}

export class ManDisEditError implements Action {
  readonly type = ActionTypes.MAN_DIS_EDIT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class ManDisDelete implements Action {
  readonly type = ActionTypes.MAN_DIS_DELETE_RECORD;
  constructor(public id: number) {}
}

export class ManDisDeleteSuccess implements Action {
  readonly type = ActionTypes.MAN_DIS_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class ManDisDeleteError implements Action {
  readonly type = ActionTypes.MAN_DIS_DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class ManDisExportGrid implements Action {
  readonly type = ActionTypes.MAN_DIS_EXPORT_DATA;
  constructor(public payload?: any) {}
}

export class ManDisExportGridSucees implements Action {
  readonly type = ActionTypes.MAN_DIS_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) {}
}

export class ManDisExportGridError implements Action {
  readonly type = ActionTypes.MAN_DIS_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

///////////////////////////////////////////////YEAR-CURRENCY///////////////////////////////////
export class YearEdit implements Action {
  readonly type = ActionTypes.YEAR_EDIT_RECORD;
  constructor(public payload: any) {}
}

export class YearEditSuccess implements Action {
  readonly type = ActionTypes.YEAR_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class YearEditError implements Action {
  readonly type = ActionTypes.YEAR_EDIT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class CurrencyEdit implements Action {
  readonly type = ActionTypes.CURRENCY_EDIT_RECORD;
  constructor(public payload: any) {}
}

export class CurrencyEditSuccess implements Action {
  readonly type = ActionTypes.CURRENCY_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class CurrencyEditError implements Action {
  readonly type = ActionTypes.CURRENCY_EDIT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class ExchangeLoadData implements Action {
  readonly type = ActionTypes.EXCHANGE_LOAD_DATA;
  constructor(public page?: number, public size?: number) {}
}

export class ExchangeLoadDataSucees implements Action {
  readonly type = ActionTypes.EXCHANGE_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class ExchangeLoadDataError implements Action {
  readonly type = ActionTypes.EXCHANGE_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class ExchangeDelete implements Action {
  readonly type = ActionTypes.EXCHANGE_DELETE_RECORD;
  constructor(public yearId: number) {}
}

export class ExchangeDeleteSuccess implements Action {
  readonly type = ActionTypes.EXCHANGE_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class ExchangeDeleteError implements Action {
  readonly type = ActionTypes.EXCHANGE_DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class ExchangeEdit implements Action {
  readonly type = ActionTypes.EXCHANGE_EDIT_RECORD;
  constructor(public payload: any) {}
}

export class ExchangeEditSuccess implements Action {
  readonly type = ActionTypes.EXCHANGE_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class ExchangeEditError implements Action {
  readonly type = ActionTypes.EXCHANGE_EDIT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class ExchangePageSizeChanged implements Action {
  readonly type = ActionTypes.EXCHANGE_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

///////////////////////////////////////////////CM-CURRENCY///////////////////////////////////

export class CMLoadData implements Action {
  readonly type = ActionTypes.CM_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[]
  ) {}
}

export class CMLoadDataSucees implements Action {
  readonly type = ActionTypes.CM_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class CMLoadDataError implements Action {
  readonly type = ActionTypes.CM_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class CMPageChanged implements Action {
  readonly type = ActionTypes.CM_PAGE_CHANGED;
  constructor(public page?: number) {}
}

export class CMSortChanged implements Action {
  readonly type = ActionTypes.CM_SORT_CHANGED;
  constructor(public sort?: ISortModel) {}
}

export class CMFilterChanged implements Action {
  readonly type = ActionTypes.CM_FILTER_CHANGED;
  constructor(public filter?: IFilterModel) {}
}

export class CMEdit implements Action {
  readonly type = ActionTypes.CM_EDIT_RECORD;
  constructor(public payload: any) {}
}

export class CMEditSuccess implements Action {
  readonly type = ActionTypes.CM_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class CMEditError implements Action {
  readonly type = ActionTypes.CM_EDIT_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class CMDelete implements Action {
  readonly type = ActionTypes.CM_DELETE_RECORD;
  constructor(public CMID: number) {}
}

export class CMDeleteSuccess implements Action {
  readonly type = ActionTypes.CM_DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class CMDeleteError implements Action {
  readonly type = ActionTypes.CM_DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}

export class CMExportGrid implements Action {
  readonly type = ActionTypes.CM_EXPORT_DATA;
  constructor(public payload?:any) {}
}

export class CMExportGridSucees implements Action {
  readonly type = ActionTypes.CM_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) {}
}

export class CMExportGridError implements Action {
  readonly type = ActionTypes.CM_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class CMSiteExportGrid implements Action {
  readonly type = ActionTypes.CMSite_EXPORT_DATA;
  constructor(public payload?:any) {}
}

export class CMSiteExportGridSucees implements Action {
  readonly type = ActionTypes.CMSite_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) {}
}

export class CMSiteExportGridError implements Action {
  readonly type = ActionTypes.CMSite_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class CMPageSizeChanged implements Action {
  readonly type = ActionTypes.CM_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}

////////////////////////////////////////////////DDL//////////////////////////////////////////////
export class GetManList implements Action {
  readonly type = ActionTypes.GET_MAN_List;
  constructor() {}
}

export class GetManListSuccess implements Action {
  readonly type = ActionTypes.GET_MAN_List_SUCCESS;
  constructor(public payload: IManufacturers[]) {}
}

export class GetManListError implements Action {
  readonly type = ActionTypes.GET_MAN_List_ERROR;
  constructor(public payload: any) {}
}

export class GetDisList implements Action {
  readonly type = ActionTypes.GET_DIS_List;
  constructor() {}
}

export class GetDisListSuccess implements Action {
  readonly type = ActionTypes.GET_DIS_List_SUCCESS;
  constructor(public payload: IDistributor[]) {}
}

export class GetDisListError implements Action {
  readonly type = ActionTypes.GET_DIS_List_ERROR;
  constructor(public payload: any) {}
}

export class GetCommList implements Action {
  readonly type = ActionTypes.GET_COMM_List;
  constructor() {}
}

export class GetCommListSuccess implements Action {
  readonly type = ActionTypes.GET_COMM_List_SUCCESS;
  constructor(public payload: ICommodity[]) {}
}

export class GetCommListError implements Action {
  readonly type = ActionTypes.GET_COMM_List_ERROR;
  constructor(public payload: any) {}
}

export class GetCommOwnersList implements Action {
  readonly type = ActionTypes.GET_COMM_OWNERS_List;
  constructor(public commId:any) {}
}

export class GetCommOwnersListSuccess implements Action {
  readonly type = ActionTypes.GET_COMM_OWNERS_List_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCommOwnersListError implements Action {
  readonly type = ActionTypes.GET_COMM_OWNERS_List_ERROR;
  constructor(public payload: any) {}
}
export class GetStatusList implements Action {
  readonly type = ActionTypes.GET_STATUS_List;
  constructor() {}
}

export class GetStatusListSuccess implements Action {
  readonly type = ActionTypes.GET_STATUS_List_SUCCESS;
  constructor(public payload: IManufacturerStatus[]) {}
}

export class GetStatusListError implements Action {
  readonly type = ActionTypes.GET_STATUS_List_ERROR;
  constructor(public payload: any) {}
}

export class GetTypeList implements Action {
  readonly type = ActionTypes.GET_TYPE_List;
  constructor() {}
}

export class GetTypeListSuccess implements Action {
  readonly type = ActionTypes.GET_TYPE_List_SUCCESS;
  constructor(public payload: IDistributorType[]) {}
}

export class GetTypeListError implements Action {
  readonly type = ActionTypes.GET_TYPE_List_ERROR;
  constructor(public payload: any) {}
}

/////////////////////////////////////LOA//////////////////////////////////////////

export class LOAsLoadData implements Action {
  readonly type = ActionTypes.LOA_GRID_LOAD_DATA;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[],
    public actionType?: actionType
  ) {}
}

export class LOAsLoadDataSuceess implements Action {
  readonly type = ActionTypes.LOA_GRID_LOAD_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class LOAsLoadDataError implements Action {
  readonly type = ActionTypes.LOA_GRID_LOAD_DATA_ERROR;
  constructor(public payload: any) {}
}

export class LOAsSortChanged implements Action {
  readonly type = ActionTypes.LOA_SORT_CHANGED;
  constructor(public page?: number) {}
}
export class LOAsPageChanged implements Action {
  readonly type = ActionTypes.LOA_PAGE_CHANGED;
  constructor(public page?: number) {}
}
export class LOAsFilterChanged implements Action {
  readonly type = ActionTypes.LOA_FILTER_CHANGED;
  constructor(public filter?: any) {}
}
export class LOAsPageSizeChanged implements Action {
  readonly type = ActionTypes.LOA_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}
export class ShareLOAs implements Action {
  readonly type = ActionTypes.SHARE_LOA;
  constructor(public payload: LoaUpdateStatusDTO) {}
}
export class EmptyShareLOAs implements Action {
  readonly type = ActionTypes.EMPTY_SHARE_LOA;
  constructor() {}
}
export class ShareLOAsSuccess implements Action {
  readonly type = ActionTypes.SHARE_LOA_SUCCESS;
  constructor(public payload: any) {}
}
export class ShareLOAsError implements Action {
  readonly type = ActionTypes.SHARE_LOA_ERROR;
  constructor(public payload: any) {}
}
export class ExportLOAPdf implements Action {
  readonly type = ActionTypes.EXPORT_LOA_PDF;
  constructor(public payload: ExportLoaFileDTO) {}
}


export class ExportLOAPdfSuccess implements Action {
  readonly type = ActionTypes.EXPORT_LOA_PDF_SUCCESS;
  constructor(public payload: any, public fileName: any) {
    
  }
}
export class ExportLOAPdfError implements Action {
  readonly type = ActionTypes.EXPORT_LOA_PDF_ERROR;
  constructor(public payload: any) {}
}


//////////////////////////////////////MASSIVE UPLOAD////////////////////////
export class SetCurrentActionData implements Action {
  readonly type = ActionTypes.SET_CURRENT_ACTION_DATA;
  constructor(public actionType?: string, fileType?:UploadTypes | null, public fileId?: number | null) {}
}
export class CheckIsActiveFile implements Action {
  readonly type = ActionTypes.CHECK_IS_ACTIVE_FILE;
  constructor() {}
}


export class CheckIsActiveFileSuccess implements Action {
  readonly type = ActionTypes.CHECK_IS_ACTIVE_FILE_SUCCESS;
  constructor(public payload: any) {}
}
export class CheckIsActiveFileError implements Action {
  readonly type = ActionTypes.CHECK_IS_ACTIVE_FILE_ERROR;
  constructor(public payload: any) {}
}
export class EmptyIsActiveFileStatus implements Action {
  readonly type = ActionTypes.EMPTY_IS_ACTIVE_FILE_STATUS;
  constructor() {}
}

export class EmptyUploadedActiveFileStatus implements Action {
  readonly type = ActionTypes.EMPTY_UPLOADED_ACTIVE_FILE_STATUS;
  constructor() {}
}

export class DiscardActiveFile implements Action {
  readonly type = ActionTypes.DISCARD_ACTIVE_FILE;
  constructor(public fileId: string) {}
}
export class DiscardActiveFileSuccess implements Action {
  readonly type = ActionTypes.DISCARD_ACTIVE_FILE_SUCCESS;
  constructor(public payload: any) {}
}
export class DiscardActiveFileError implements Action {
  readonly type = ActionTypes.DISCARD_ACTIVE_FILE_ERROR;
  constructor(public payload: any) {}
}

export class StartUploadFile implements Action {
  readonly type = ActionTypes.START_UPLOAD_FILE;
  constructor(public payload: any,public fileType?:string) {}
}
export class  StartUploadFileSuccess implements Action {
  readonly type = ActionTypes.START_UPLOAD_FILE_SUCCESS;
  constructor(public payload: any) {}
}
export class  StartUploadFileError implements Action {
  readonly type = ActionTypes.START_UPLOAD_FILE_ERROR;
  constructor(public payload: any) {}
}


export class MapUploadedFile implements Action {
  readonly type = ActionTypes.MAP_UPLOADED_FILE;
  constructor(
    public fileType?:string,
    public fileId?:any
  ) {}
}

export class MapUploadedFileSuccess implements Action {
  readonly type = ActionTypes.MAP_UPLOADED_FILE_SUCCESS;
  constructor(public payload: any) {}
}

export class MapUploadedFileError implements Action {
  readonly type = ActionTypes.MAP_UPLOADED_FILE_ERROR;
  constructor(public payload: any) {}
}



export class ValidateUploadedFile implements Action {
  readonly type = ActionTypes.VALIDATE_UPLOADED_FILE;
  constructor(
    public fileId?: number,
    public mappedColumns?: GridHeaderData[],
    public startRow?: number,
    public fileType?: UploadTypes
  ) {}
}

export class ValidateUploadedFileSuccess implements Action {
  readonly type = ActionTypes.VALIDATE_UPLOADED_FILE_SUCCESS;
  constructor(public payload: any) {}
}

export class ValidateUploadedFileError implements Action {
  readonly type = ActionTypes.VALIDATE_UPLOADED_FILE_ERROR;
  constructor(public payload: any) {}
}


export class ExportValidatedFile implements Action {
  readonly type = ActionTypes.EXPORT_VALIDATED_UPLOADED_FILE;
  constructor(
    public fileId?: any,
    public option?: any,
    public fileName?: any,
    public fileType?: any
  ) {
  }
}

export class ExportValidatedFileSuccess implements Action {
  readonly type = ActionTypes.EXPORT_VALIDATED_UPLOADED_FILE_SUCCESS;

  constructor(public payload: any, public fileName: any) {}
}

export class ExportValidatedFileError implements Action {
  readonly type = ActionTypes.EXPORT_VALIDATED_UPLOADED_FILE_ERROR;
  constructor(public payload: any) {}
}


export class SubmitUploadedFile implements Action {
  readonly type = ActionTypes.SUBMIT_UPLOADED_FILE;
  constructor(public fileId?:number,public fileType?:any) {}
}

export class SubmitUploadedFileSuccess implements Action {
  readonly type = ActionTypes.SUBMIT_UPLOADED_FILE_SUCCESS;
  constructor(public payload: any) {}
}

export class SubmitUploadedFileError implements Action {
  readonly type = ActionTypes.SUBMIT_UPLOADED_FILE_ERROR;
  constructor(public payload: any) {}
}


///////////////////////////////////////GRID ACTIONS////////////////////////////////////////
// load data in grid 
export class LoadDataGrid implements Action {
  readonly type = ActionTypes.LOAD_DATA_Grid;
  constructor(
    public page?: number,
    public size?: number,
    public filter?: FilterDescriptor[],
    public sort?: SortDescriptor[],
    public gridType?:string
  ) {}
}
export class LoadDataGridSuccess implements Action {
  readonly type = ActionTypes.LOAD_DATA_Grid_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadDataGridError implements Action {
  readonly type = ActionTypes.LOAD_DATA_Grid_ERROR;
  constructor(public payload: any) {}
}


// delete record 
export class DeleteRecord implements Action {
  readonly type = ActionTypes.DELETE_RECORD;
  constructor(public payload: any,public gridType:string) {}
}
export class DeleteRecordSuccess implements Action {
  readonly type = ActionTypes.DELETE_RECORD_SUCCESS;
  constructor(public payload: any) {}
}
export class DeleteRecordError implements Action {
  readonly type = ActionTypes.DELETE_RECORD_ERROR;
  constructor(public payload: any) {}
}

// record actions on grids
export class RecordPageChanged implements Action {
  readonly type = ActionTypes.RECORD_PAGE_CHANGED;
  constructor(public page?: number) {}
}

export class RecordSortChanged implements Action {
  readonly type = ActionTypes.RECORD_SORT_CHANGED;
  constructor(public sort?: SortDescriptor[]) {}
}

export class RecordFilterChanged implements Action {
  readonly type = ActionTypes.RECORD_FILTER_CHANGED;
  constructor(public filter?: FilterDescriptor[]) {
  }
}

export class RecordPageSizeChanged implements Action {
  readonly type = ActionTypes.RECORD_PAGE_SIZE_CHANGED;
  constructor(public pageSize?: number) {}
}


//add or edit
export class AddEditRecord implements Action {
  readonly type = ActionTypes.ADD_EDIT_RECORD;
  constructor(public payload: any,public gridType:string, public apiType:string) {}
}

export class AddEditRecordSuccess implements Action {
  readonly type = ActionTypes.ADD_EDIT_RECORD_SUCCESS;
  constructor(public payload: any) {}
}

export class AddEditRecordError implements Action {
  readonly type = ActionTypes.ADD_EDIT_RECORD_ERROR;
  
  constructor(public payload: any) {}
}
//---------------------------CustomerCommodityExportGrid---------------///
export class CustomerCommodityExportGrid implements Action {
  readonly type = ActionTypes.Customer_Commodity_EXPORT_DATA;
  constructor( public payload?:any) {
  }
}
export class CustomerCommodityExportGridSucees implements Action {
  readonly type = ActionTypes.Customer_Commodity_EXPORT_DATA_SUCCESS;
  constructor(public payload: ArrayBuffer) {}
}
export class CustomerCommodityExportGridError implements Action {
  readonly type = ActionTypes.Customer_Commodity_EXPORT_DATA_ERROR;
  constructor(public payload: any) {}
}

export class AddEditCommodity implements Action {
  readonly type = ActionTypes.ADD_EDIT_COMMODITY;
  constructor(public payload: any, public apiType:string) {}
}
//---------------------------CustomerCommodity----------------------///

export class AddEditCommoditySuccess implements Action {
  readonly type = ActionTypes.ADD_EDIT_COMMODITY_SUCCESS;
  constructor(public payload: any) {}
}

export class AddEditCommodityError implements Action {
  readonly type = ActionTypes.ADD_EDIT_COMMODITY_ERROR;
  constructor(public payload: any) {}
}

// log out 
export class LogOut implements Action {
  readonly type = ActionTypes.LOG_OUT;
  constructor(public payload?: any) { }
}
export class LogOutSuccess implements Action {
  readonly type = ActionTypes.LOG_OUT_SUCCESS;
  constructor(public payload?: any) { }
}
export class LogOutError implements Action {
  readonly type = ActionTypes.LOG_OUT_ERROR;
  constructor(public payload?: any) { }
}