<kendo-grid
  #grid="kendoGrid"
  [ngClass]="{ 'hide-pager': hidePager }"
  [data]="{
    data: rowData?.data || [],
    total: rowData?.total || 0
  }"
  [skip]="offset || 1"
  [pageSize]="pageSize"
   [pageable]="{
    buttonCount: buttonCount,
    info: info,
    type: 'numeric',
    pageSizes: pageSizes,
    previousNext: previousNext,
    position: 'bottom'
  }"
  [resizable]="true"
  [reorderable]="true"
  [filter]="gridFilters"
  (pageChange)="pageChange($event)"
  (filterChange)="filterChange($event)"
  [selectable]="selectable"
  [kendoGridSelectBy]="selectedby"
  [(selectedKeys)]="selectedrows"
  [rowClass]="isDisabled"
  (selectionChange)="selectionChangeHandler($event)"
  [sortable]="{
    allowUnsort: allowUnsort,
    mode: 'single'
  }"
  [sort]="sort"
  [hideHeader]="hideheader"
  (sortChange)="sortChange($event)"
  [filterable]="filterable"
  (dataStateChange)="dataStateChange($event)"
  (columnVisibilityChange)="columnVisibilityChange($event)"
  [style]="height"
>
  <ng-template *ngIf="columnsetting" kendoGridToolbarTemplate>
    <kendo-grid-spacer></kendo-grid-spacer>
    Column Setting
    <kendo-grid-column-chooser> </kendo-grid-column-chooser>
  </ng-template>
  <kendo-grid-checkbox-column
    *ngIf="AddCheckBoxCoulmn"
    [width]="50"
    [showSelectAll]="hasSelectAllBtn"
  ></kendo-grid-checkbox-column>

  <kendo-grid-column
    *ngFor="let header of gridHeaders; let i = index"
    [title]="header.title"
    [filterable]="header.actions || header.filterable === false ? false : true"
    [field]="header.field"
    [sortable]="header.actions || header.sortable === false ? false : true"
    [class]="{ 'actionMenu-sticky': header.key === 'action' }"
    [headerClass]="header.key === 'action' ? 'actionMenu-sticky' : ''"
    [reorderable]="header.reorderable === false ? false : true"
    [width]="width"
    [locked]="header.locked ? true : false"
    [lockable]="header.locked ? true : false"
    [hidden]="header.hidden ? true : false"
    filter="date" 
    [format]="header.isDate?'{0:yyyy-MM-dd}':''"
  >
    <ng-template
      kendoGridHeaderTemplate
      let-dataItem
      let-columnIndex="columnIndex"
      let-column="column"
      let-row="row"
      *ngIf="header.dropdown"
    >
      <app-dropdown-list
        [data]="dropdownItems"
        [selectedItem]="getSelectedValue(selcetdeHeaders, column, columnIndex)"
        textField="title"
        valueField="title"
        (valueChange)="selectValueChange($event, columnIndex)"
      >
      </app-dropdown-list>
    </ng-template>
    <ng-template
      *ngIf="header.field === 'Actions'"
      kendoGridCellTemplate
      let-action
      let-data
      let-column="column"
      let-columnIndex="columnIndex"
    >
      <span
        *ngFor="let icon of getCellActions(header.actions, data, column)"
        class="k-icon {{ icon.actionType }} grid-icon"
        (click)="edit(icon, data)"
      ></span>
    </ng-template>
    <ng-template
      kendoGridCellTemplate
      let-dataItem
      let-column="column"
      let-columnIndex="columnIndex"
      let-rowIndex="rowIndex"
    >
      <span *ngIf="header.type === ColumnType.DATE">
        {{
          getCellValue(dataItem, column, columnIndex, rowIndex)
            | date: "yyyy-MM-dd"
        }}
      </span>
      <span *ngIf="header.type === ColumnType.CHECKBOX">
        <input
          type="checkbox"
          [checked]="dataItem[column.field]"
          (change)="checkBoxAction($event, dataItem)"
          disabled
        />
      </span>
      <span *ngIf="header.type === ColumnType.TEXT || !header.type">
        {{ getCellValue(dataItem, column, columnIndex, rowIndex) }}
      </span>
      <span *ngIf="header.type === ColumnType.LINK">
        <a class="link" href="{{ dataItem[column.field] }}" target="_blank">
          {{ dataItem[column.field] }}
        </a>
      </span>
      <span *ngIf="header.type === ColumnType.LINKWITHNAVIGATIONROUTE">
        <a class="link" (click)="navigateToItemLink(dataItem)">
          {{ dataItem[column.field] }}
        </a>
      </span>
      <span *ngIf="header.type === ColumnType.LinkNotClickable" class="link">
        {{ getCellValue(dataItem, column, columnIndex, rowIndex) }}
      </span>
      <span *ngIf="header.isCompared">
        <button (click)="addtocompare(dataItem,column)" >Add To Compare</button>
        
      </span>
    </ng-template>
    
     
      <ng-template
      kendoGridFilterMenuTemplate
      *ngIf="header.filterable && !header.isDate"
      let-column="column"
      let-filter="filter"
      let-filterService="filterService"
    >
    
      <input
      type="text"
      [(ngModel)]="searchText[i].value"
      (input)="searchTextChange(header.field, filterService, i)"/>
    </ng-template>  
    
    
    <ng-template *ngIf="header.isDate"
     kendoGridFilterMenuTemplate
      let-filter
       let-column="column"
        let-filterService="filterService">

      <kendo-grid-date-filter-menu class="date-filter"
          [column]="column"
          [filter]="filter" 
          [filterService]="filterService"
          [extra]="false"
          operator="eq" 
          >
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>
 
</kendo-grid>
