
export enum ReportTypeEnum {
  lowestCostReport = 'Lowest Cost',
  materialRiskReport = 'Material Risk Report',
  // topSpendItem = 'Top Spend Item and Show Risks',
  // savingByBU = 'Saving By BU',
   internalCrosses = 'Internal Crosses',
  // historicalData = 'Historical data by Assembly Level',
  tariffImpact = 'Tariff Impact',
  BestPrice = 'Best Price',
  BestLT = 'Best LT',
  SupplierScore = 'Supplier Score',
  UsersScore = 'Users Score',
}
export enum ReportEnum {
  cmt = 'cmt',
  rfq = 'rfq',

}
export interface IReport {
  reportName: string;
  reportId: number;
}
export class CMTReports {
  report: IReport[] = [
    {
      reportName: ReportTypeEnum.materialRiskReport,
      reportId: 8
    },
    {
      reportName: ReportTypeEnum.lowestCostReport,
      reportId: 9
    },
    // {
    //   reportName: ReportTypeEnum.savingByBU,
    //   reportId: 2
    // },
    {
      reportName: ReportTypeEnum.internalCrosses,
      reportId: 5
    },
    // {
    //   reportName: ReportTypeEnum.historicalData,
    //   reportId: 6
    // },
    {
      reportName: ReportTypeEnum.tariffImpact,
      reportId: 3
    },
  ];
}
export class RFQReports {
  report: IReport[] = [

    {
      reportName: ReportTypeEnum.BestPrice,
      reportId: 11
    },
    {
      reportName: ReportTypeEnum.BestLT,
      reportId: 12
    },
    {
      reportName: ReportTypeEnum.SupplierScore,
      reportId: 13
    },
    {
      reportName: ReportTypeEnum.UsersScore,
      reportId: 14
    },

  ];
}
export class RFQReportObj {
  FileName: string;
  ReportTypeID: number;
  YearId: number;
}
export class PivoteReportObj{
  Column:string;
  Row:string;
  Filter:string;
  AggregateColumnDataType:string;
  AggregateColumn:string;
  Aggregate:string;
  TableName:string;
   YearId:number;
}
export class DataObj{
  ColName:string;
  Value:string;
}
export var allReports: string[] = [
  ReportTypeEnum.lowestCostReport,
  ReportTypeEnum.materialRiskReport,
  // ReportTypeEnum.savingByBU,
   ReportTypeEnum.internalCrosses,
  // ReportTypeEnum.historicalData,
  ReportTypeEnum.tariffImpact,
  ReportTypeEnum.BestPrice,
  ReportTypeEnum.BestLT,
  ReportTypeEnum.SupplierScore,
  ReportTypeEnum.UsersScore,

];

export interface ISource {
  source: string;
  sourceId: number;
}

export interface SourceColumn {
  id: number;
  displayName: string;
  dataType: string;
}
export interface IExportPivoteTable {
  pivoteColumnsDTO: PivotData;
  FileName: string;
}
export interface PivotData {
  columnId: number;
  rowId: number;
  yearId: number;
  filterId: number;
  filterValue: string;
  aggregateColumnId: number;
  aggregate: string;
}

export interface IGraphData{
  column: string;
  value: number;
  row: string;
}

export interface InputGraphData {
  headers: any[];
  data: any[];
}
