import { Component, OnInit } from '@angular/core';
import { ProviderClass } from 'src/app/shared/classes/ProviderClass';

@Component({
  selector: 'app-crosses-details',
  templateUrl: './crosses-details.component.html',
  styleUrls: ['./crosses-details.component.scss']
})
export class CrossesDetailsComponent implements OnInit {
  seCrossesDetails: ProviderClass;

  constructor(
    public dataIn: ProviderClass
  ) {
    this.seCrossesDetails = this.dataIn;
  }

  ngOnInit() {
  }

}
