import { IComponentColumn } from 'src/app/core/parts/components/components.interfaces';
import { actionMenu, GridHeaderData } from 'src/app/shared/shared.interface';
import { PartDetailshComponent } from '../../../components/part-details/part-details.component';
import { ViewComponentComponent } from '../components/view-component/view-component.component';

export const headers: GridHeaderData[] = [
  {
    actions: [
      {
        componentData: {
          componentTilte: 'View Component',
          componentName: ViewComponentComponent,
          open: true,
        },
        actionType: actionMenu.view,
      }
    ],
    field: 'Actions',
    title: ''
  }, 
  {
    field: 'CM',
    title: 'CM',
    filterable: true,
    sortable: true
  }, 
  {
    field: 'PartNumber',
    title: 'Part Number',
    filterable: true,
    sortable: false
  },
  {
    field: 'SiteName',
    title: 'Site Name',
    filterable: true,
    sortable: false
  },
  {
    field: 'EAU',
    title: 'EAU',
    filterable: true,
    sortable: false
  },
  {
    field: 'CurrentSupplier',
    title: 'Current Supplier',
    filterable: true,
    sortable: false
  },
  {
    field: 'CurrentManufacturer',
    title: 'Current Manufacturer',
    filterable: true,
    sortable: false
  },
  {
    field: 'ManufacturerPartNumber',
    title: 'Manufacturer Part Number',
    filterable: true,
    sortable: false
  },
  {
    field: 'SEManufacture',
    title: 'SE Manufacture',
    filterable: true,
    sortable: false
  },
  {
    field: 'SEMPN',
    title: 'SE MPN',
    filterable: true,
    sortable: false
  },
  {
    field: 'Description',
    title: 'Description',
    filterable: true,
    sortable: false
  },
  {
    field: 'CurrentUnitPrice',
    title: 'Current Unit Price',
    filterable: true,
    sortable: false
  },
  {
    field: 'MOQ',
    title: 'MOQ',
    filterable: true,
    sortable: false
  },
  {
    field: 'UOM',
    title: 'UOM',
    filterable: true,
    sortable: false
  },
  {
    field: 'Commodity',
    title: 'Commodity',
    filterable: true,
    sortable: false
  },
  {
    field: 'CommodityType',
    title: 'Commodity Type',
    filterable: true,
    sortable: false
  },
  {
    field: 'CustomSoleSource',
    title: 'Custom / Sole source',
    filterable: true,
    sortable: false
  },
  {
    field: 'Spend',
    title: 'Spend',
    filterable: true,
    sortable: false
  },
  {
    field: 'CurrentStock',
    title: 'Current Stock',
    filterable: true,
    sortable: false
  },
  {
    field: 'Remarks',
    title: 'Remarks',
    filterable: true,
    sortable: false
  },
  {
    field: 'SELifeCycleStatus',
    title: 'SE Life cycle Status',
    filterable: true,
    sortable: false
  },
  {
    field: 'SEAVGPrice',
    title: 'SE AVG Price',
    filterable: true,
    sortable: false
  },
  {
    field: 'SEInventory',
    title: 'SE Inventory',
    filterable: true,
    sortable: false
  },
  {
    field: 'SELT',
    title: 'SE LT (Weeks)',
    filterable: true,
    sortable: false
  },
  {
    field: 'ACL',
    title: 'ACL',
    filterable: true,
    sortable: false
  },
  {
    field: 'AML',
    title: 'AML',
    filterable: true,
    sortable: false
  },
  {
    field: 'SnappedPrice',
    title: 'Snapped Price',
    filterable: true,
    sortable: false
  },
  {
    field: 'Price',
    title: 'Price',
    filterable: true,
    sortable: false
  },
  {
    field: 'PriceDiff',
    title: 'Price Diff',
    filterable: true,
    sortable: false
  },
  {
    field: 'SnappedQTY',
    title: 'Snapped QTY',
    filterable: true,
    sortable: false
  },
  {
    field: 'QTY',
    title: 'QTY',
    filterable: true,
    sortable: false
  },
  {
    field: 'QTYDiff',
    title: 'QTY Diff',
    filterable: true,
    sortable: false
  },
  {
    field: 'SnappedLeadTime',
    title: 'Snapped Lead Time',
    filterable: true,
    sortable: false
  },
  {
    field: 'LeadTime',
    title: 'Lead Time',
    filterable: true,
    sortable: false
  },
  {
    field: 'LeadTimeDiff',
    title: 'Lead Time Diff',
    filterable: true,
    sortable: false
  },
  {
    field: 'Spot',
    title: 'Spot buy is lower than negotiated',
    filterable: true,
    sortable: false
  },
  {
    field: 'SafetyStockLevel',
    title: 'Safety Stock Level',
    filterable: true,
    sortable: false
  },
  {
    field: 'CriticalPart',
    title: 'Critical Part',
    filterable: true,
    sortable: false
  },
  {
    field: 'PartFamily',
    title: 'Part Family',
    filterable: true,
    sortable: false
  }
];



export function getGridViewHearders(dynamicColumns: IComponentColumn[] = []): any[] {
  return [
   
    {
      actions: [
        {
          componentData: {
            componentTilte: '',
            componentName: PartDetailshComponent,
            open: true,
          },
          actionType: actionMenu.view,
        }
      ],
      field: 'Actions',
      title: 'Actions'
    },
    ...dynamicColumns?.map((c: any) => {
       return {
        field: c.colAlias,
        title: c.partsFeatureName,
        filterColName:c.colName,
        filterable: true,
        sortable: true,
      };
    }),
 
    
  ];
}