import {
  IApiRequestState,
  IListingState,
} from 'src/app/shared/shared.interface';

export interface IUploadState {
  requestGrid: IListingState<ITicketData>;
  historyGrid: any;
  supplierGrid: any;
  supplierAwardedQuotesGrid: any;
  updateAwardedQuoteAckStatus: any;
  updateAwardedQuoteAckStatusBySelection: any;
  filter: any;
  currentAction: ICurrentActionData;
  currentExistAction: ICurrentActionData;
  currentSupplierAction: ICurrentResponseActionData;
  currentUpdateExistingAction: ICurrentResponseActionData;
  reviewGrid?: IListingState<IUploadResponse>;
  supplierReviewGrid?: IListingState<ISupplierUploadResponse>;
  validateFile: IValidateFile;
  updateExistingFileStatus: IValidateFile;
  discard: IDiscard;
  uploadFile: IUPloadFile;
  ticketFileSubmited: ITicketFileSubmited;
  normalizationResult: INormalizationResult;
   close: IDiscard;
  supplierGridFilter: any;
  supplierGridSort: any;
  supplierAwardedQuotesGridSort: any;
  supplierAwardedQuotesGridFilter: any;
  quotePartsDetails: any;
  awardedQuotePartsDetails: any;
  supplierUploadFile: IUPloadFile;
  validateSupplierFile: IValidateFile;
  uploadSupplierFile: IUPloadFile;
  normalizationSupplierResult: INormalizationResult;
  supplierFileSubmitted: ISupplierFileSubmited;
  awardedQuotePartsDetailsGrid : any;
  awardedQuoteDetails: any;
}
export interface ExportSupplierQuotesDto {
  quoteName: string;
  noOfParts: number;
  rFQInDate: string | null;
  dueDate: string | null;
  status: string;
  priority: string;
  clientName: string;
  clientEmail: string;
}
export interface INormalizationResult extends IApiRequestState {
  duplicateCount: number;
  errorCount: number;
  isNormalized: boolean | null;
  normalizedGrid: IListingState<IUploadResponse>;
}

export interface ITicketFileSubmited extends IApiRequestState {
  ticketFileSubmited: boolean;
}

export interface IDiscard extends IApiRequestState {
  status: string | undefined;
}

export interface IUploadResponse {
  ticketId: number;
  fileId: number;
  rows: Array<IRows>;
  columns: Array<UploadColumns>;
  DDL?: Array<UploadColumns>;
  valid?:boolean;
}

export interface ISupplierUploadResponse {
  quoteId: number;
  fileId: number;
  rows: Array<IRows>;
  columns: Array<UploadColumns>;
  DDL?: Array<UploadColumns>;

}

export interface IRows {
  rowIndex: number;
  cells: Array<ICell>;
  rowComment: any;
}

export interface ICell {
  columnIndex: number;
  value: string;
}

export interface ITicketKendoUIGrid {
  TicketId: number;
  AssigneeId: number;
  TicketTypeId: number;
  YearId: number;
  Assignee: string;
  RequesterId: number;
  Requester: string;
  Year: string;
  TicketType: string;
  TicketStatus: string | null;
  ContractManufacturerName: string;
  InsertionDate: string | null;
  LastUpdateDate: string | null;
}

export interface UploadColumns {
  index: number;
  featureName: string;
  featureId: number;
  required: boolean;
}

export interface IUPloadFile extends IApiRequestState {
  fileId: number;
  isActive: string;
  tempId?: string;
}

export interface IValidateFile extends IApiRequestState {
  fileData: IFileData | null;
}

export interface IFileData {
  fileId: number;
  isActive: string;
  tempResponseId: string;
}
export interface ICurrentActionData {
  actionType: string;
  ticketId?: string | null;
  fileId?: string | null;
  ticketType?: string | null;
}

export interface ICurrentResponseActionData {
  actionType: string;
  id?: string | null;
  fileId?: string | null;
}

export class ticketType {
  ticketTypeId: number;
  ticketType: CardTypeEnum;
}

export enum CardTypeEnum {
  COMPONENT = 'Component',
  CBOM = 'CBOM',
  PRICE_PREVIEW = 'Component Price Review',
}

export enum CardStatusEnum {
  NEW = '1',
  REVIEW = '2',
  REJECTED = '3',
}

export interface ITicketData {
  assignee: string;
  fileId: string;
  requester: IRequester;
  ticketId: number;
  insertionDate: Date;
  ticketType: ITicketType;
  ticketStatus: ITicketStatus;
  user: IUser;
  year: IYear;
  status: CardStatusEnum;
  contractManufacturer: IContractManfacturer;
}
export interface IContractManfacturer {
  contractManufacturerId: number,
    contractManufacturerName: string;
}
export interface IUserTicketData {
  userTicketsData: ITicketData[];
}

export interface IDropDownHeader {
  dropDownHeader: boolean;
  columns: Array<columns>;
  selected: Array<columns>;
}

export interface columns {
  index: number;
  name: string;
  title?: string;
  required?: boolean;
}

export enum UploadAction {
  UPLOAD = 'upload',
  NORMALIZE = 'normalize',
  REVIEW = 'review',
  SUBMIT = 'submit',
}

export enum UpdateExistingType {
  COMPONENT = 'component',
  CBOM = 'cbom'
}

export enum fileType{
  ENGINEER = 'Engineer',
  COMPONENT = 'component',
  CBOM = 'cbom'
}

export interface IMappedDataRequest {
  ticketId: string;
  fileId: string;
  startRow: number;
  cmMappedColumns: columns[];
}

export interface IMappedSupplierDataRequest {
  quoteId: string;
  fileId: string;
  startRow: number;
  cmMappedColumns: columns[];
}

export interface IRequester {
  userId: string;
  fullName: string;
}
export interface IUser {
  userId: string;
  fullName: string;
}

export interface IYear {
  yearId: string;
  year: string;
}

export interface ITicketType {
  ticketTypeId: string;
  ticketType: CardTypeEnum;
}

export interface ITicketStatus {
  ticketStatusId: string;
  ticketStatus: string;
}


export interface ISubmitSupplierResponse {
  FileId: number;
  QuoteId: number;
  TempSupplierResponse: string;
}

export interface ISupplierMappedDataRequest {
  quoteId: any;
  fileId: string;
  startRow: number;
  columns: columns[];
  tempResponseId: string;
}

export interface ISupplierFileSubmited extends IApiRequestState {
  supplierFileId: any;
}
