import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthTokenService } from './auth-token.service';
import { IAuthResult } from './auth.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public SERVICE_URL = `${environment.baseUrls.serviceSecurity}/api/authenticate`;
  constructor(
    private authTokenService: AuthTokenService,
    private http: HttpClient
  ) {}
  /**
   * Login and authenticate user then set api token on success
   */
  public login(username: string, password: string) {
    return this.loginWithClientId(username, password, 'CLIENT_ID');
  }
  public loginWithClientId(
    username: string,
    password: string,
    clientId: string
  ) {
    /* const authenticate$ = */ return this.authenticate(
      username.toLocaleLowerCase().trim(),
      password
    );
    /*return forkJoin([authenticate$]).pipe(
      tap(([authenticate]) => {
        this.authTokenService.setTokens(authenticate);
      }),
      shareReplay()
    );*/
  }

  public authenticate(username: string, password: string) {
    return this.http.post<IAuthResult>(this.SERVICE_URL, {
      username: username,
      password: password,
    });
  }

  public isUserAuthenticated() {
    const refreshToken = this.authTokenService.getValidRefreshToken();

    if (refreshToken !== null) {
      return of(true);
    }

    return of(false);
  }
}
