import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewEncapsulation,
  OnInit,
  Injector,
  StaticProvider,
  ChangeDetectorRef,
  AfterContentChecked,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Dialog } from 'src/app/shared/models/model.interface';
import { DialogShareService } from '../dialog.service';
import { ProviderClass } from 'src/app/shared/classes/ProviderClass';

@Component({
  selector: 'app-new-window',
  templateUrl: './new-window.component.html',
  styleUrls: ['./new-window.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewWindowComponent
  implements OnDestroy, OnInit, AfterContentChecked {
  @Input() openedComponent: any;
  disable:boolean=false;
  @Input() title: string = ' Component Title ';
  @Input() cancelText = 'close';
  @Input() submitText = 'Save';
  // @Input() disable = false;
  @Input() RejectText = 'Reject';
  @Input() ApproveText = 'Approve';
  @Input() width: any = 'md';
  @Input() height: any = 'md';
  @Input() windowTop = 100;
  @Input() data: any = {};
  @Input() hasSubmitBtn: boolean = true;
  @Output() closeAction = new EventEmitter();
  @Output() validateInpute = new EventEmitter();

  minWidth = 250;

  closed = false;
  subscription: Subscription | undefined;
  constructor(
    private dialogShareService: DialogShareService,
    public injector: Injector,
    private cdref: ChangeDetectorRef
  ) {
    this.dialogSubscribtion();
  }
  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.calcModalSize();
    this.createInjector(this.data);
  }

  calcModalSize() {
    switch (this.width) {
      case 'sm':
        this.width = 300;
        break;
      case 'md':
        this.width = 500;
        break;
      case 'lg':
        this.width = 800;
        break;
      case 'xl':
        this.width = 1140;
        // this.width = 1300;
        break;
      default:
        break;
    }
  }

  submit() {
    if (this.submitText === Dialog.approve) {
      this.dialogShareService.sendMessage(Dialog.approve);
      this.closed = true;
      this.closeAction.emit(true);
    }
    else if(this.submitText === Dialog.Resend){
      this.dialogShareService.sendMessage(Dialog.Resend);
    }
    else if(this.submitText === Dialog.Proceed){
      this.dialogShareService.sendMessage(Dialog.Proceed);
    }
    else {
      this.dialogShareService.sendMessage(Dialog.submit);
    }
  }

  cancel() {
    if (this.cancelText === Dialog.reject) {
      this.dialogShareService.sendMessage(Dialog.reject);
      this.closed = true;
      this.closeAction.emit(true);
    }
    else if(this.cancelText === Dialog.Dicard){
      this.dialogShareService.sendMessage(Dialog.Dicard);
    }
    else {
      this.dialogShareService.sendMessage(Dialog.close);
      this.closed = true;
      this.closeAction.emit(true);
    }
  }
  closeWindow() {
  //  this.dialogShareService.sendMessage(Dialog.close);
    this.closed = true;
    this.closeAction.emit(true);
  }
  openWindow() {
  //  this.dialogShareService.sendMessage(Dialog.close);
    this.closed = false;
    this.closeAction.emit(false);
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    this.data = {};
  }

  dialogSubscribtion() {
   
    this.subscription = this.dialogShareService
      .getMessage()
      .subscribe((data: any) => {
         
        if(data.res==Dialog.disable){
          this.disable=true;
        }
        else if(data.res==Dialog.enable){
          this.disable=false;
        }
        if (data.res == Dialog.close) this.closeWindow();
        if (data.res == Dialog.open) this.openWindow();
        if(data.res === Dialog.hasNotSubmitButton) this.hasSubmitBtn = false;
      });
  }

  createInjector(dataObj: any) {
    var provider: StaticProvider[] = [
      { provide: ProviderClass, useValue: dataObj },
    ];
    const options = {
      providers: provider,
      parent: this.injector,
    };
    this.injector = Injector.create(options);
  }
}
