export const allRoles: role[] = [
    {
        role: 'Dashboard',
        path: 'dashboard'
    },
    {
        role: 'Part Search',
        path: 'partsearch'
    },
]
export class AllRoles{
    roles: role[] = [
        {
          role: 'Dashboard',
          path: 'dashboard'
        },
        {
          role: 'Engineer',
          path: 'engineer'
        },
        {
          role: 'Admin',
          path: 'admin'
        },
        {
          role: 'Part Search',
          path: 'partsearch'
        },
        {
          role: 'Reports',
          path: 'reports'
        },
        {
          role: 'Manufacturers',
          path: 'manufacturers'
        },
        {
          role: 'Distributers',
          path: 'distributors'
        },
        {
          role: 'Manufacturer Distributers',
          path: 'manufacturer-distributors'
        }, {
          role: 'Commodities',
          path: 'commodities'
        },
        {
          role: 'Years',
          path: 'year-currency'
        },
        {
          role: 'Contract Manudfacturer',
          path: 'contract-manufacturers'
        },
        {
          role: 'Business Units',
          path: 'business-units'
        },
        {
          role: 'Contract Manufacturer Help',
          path: 'cmhelp'
        },
        {
          role: 'Info',
          path: 'info'
        },
        {
          role: 'Supplier Info',
          path: 'supplier-info'
        },
        {
          role: 'Strategic Sourcing Manager Help',
          path: 'commhelp'
        },
        {
          role: 'Terms',
          path: 'terms'
        },
        {
          role: 'Policies',
          path: 'policies'
        },
        {
          role: 'Engineer Help',
          path: 'engineerHelp'
        },
        {
          role: 'Tickets',
          path: 'tickets'
        }, {
          role: 'Users',
          path: 'users'
        },
        {
          role: 'Part Search',
          path: 'users'
        },
        {
          role: 'Cross Search',
          path: 'crosssearch'
        },
        {
          role: 'Component',
          path: 'components'
        },
        {
          role: 'CBOM',
          path: 'cbom'
        },
        {
          role: 'Users',
          path: 'users'
        },
        {
          role: 'Alert',
          path: 'alerts'
        },
        {
            role: 'Upload',
            path: 'upload'
          },
        {
            role: 'Quotes',
            path: 'quote'
          },
          {
            role: 'UploadQuotes',
            path: 'quote'
          },
          {
            role: 'Awards',
            path: 'award'
          },
          {
            role: 'LOAs',
            path: 'loa'
          },
        {
          role: 'Requests',
          path: 'requests'
        },
        {
          role: 'History',
          path: 'history'
        }
      ];
}
 
export interface role {
    role: string,
    path: string
}