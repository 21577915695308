import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAdminState } from 'src/app/core/Admin/admin.interfaces';

export const getAdminState = createFeatureSelector<IAdminState>('admin');

/**----------------------------------------------TICKET----------------------------------------- */

export const TicketGridDataSelector = createSelector(
  getAdminState,
  (state) => state?.ticketGrid
);

export const CompanyTypeSelector = createSelector(
  getAdminState,
  (state) => state?.companyType
);
export const RoleSelector = createSelector(
  getAdminState,
  (state) => state?.roleList
);
export const CompanySelector = createSelector(
  getAdminState,
  (state) => state?.getCompany
);

export const TicketGridDataRecordSelector = createSelector(
  getAdminState,
  (state) => state?.ticketGridRecord
);

export const TicketGridDFilterSelector = createSelector(
  getAdminState,
  (state) => state?.ticketGrid?.filter
);

export const TicketGridSortSelector = createSelector(
  getAdminState,
  (state) => state?.ticketGrid?.sort
);

export const TicketGridDeleteSelector = createSelector(
  getAdminState,
  (state) => state?.ticketGrid?.delete
);
export const TicketApproveSelector = createSelector(
  getAdminState,
  (state) => state?.approve
);
export const TicketRejectSelector = createSelector(
  getAdminState,
  (state) => state?.reject
);
export const TicketGridEditSelector = createSelector(
  getAdminState,
  (state) => state?.ticketGrid?.edit
);

export const TicketGridCurrentPageSelector = createSelector(
  getAdminState,
  (state) => state?.ticketGrid?.currentPage
);
export const TicketGridCurrentPageSizeSelector = createSelector(
  getAdminState,
  (state) => state?.ticketGrid?.pageSize
);

export const ReviewTicketApproveSelector = createSelector(
  getAdminState,
  (state) => state?.approve
);

export const ReviewTicketRejectSelector = createSelector(
  getAdminState,
  (state) => state?.reject
);

export const ReviewTicketGridSelector = createSelector(
  getAdminState,
  (state) => state?.reviewTicketGrid
);

export const selectedTicketFiltersSelector = createSelector(
  TicketGridDFilterSelector,
  TicketGridSortSelector,
  TicketGridCurrentPageSelector,
  TicketGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        ...filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filters: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);

/**----------------------------------------------CONTRACT MANUFACTURER----------------------------------------- */

export const ContractManufacturerGridDataSelector = createSelector(
  getAdminState,
  (state) => state?.cmGrid
);

export const ContractManufacturerGridDFilterSelector = createSelector(
  getAdminState,
  (state) => state?.cmGrid?.filter
);


export const ContractManufacturerGridSortSelector = createSelector(
  getAdminState,
  (state) => state?.cmGrid?.sort
);

export const ContractManufacturerGridDeleteSelector = createSelector(
  getAdminState,
  (state) => state?.cmGrid?.delete
);

export const ContractManufacturerGridEditSelector = createSelector(
  getAdminState,
  (state) => state?.cmGrid?.edit
);

export const ContractManufacturerGridCurrentPageSelector = createSelector(
  getAdminState,
  (state) => state?.cmGrid?.currentPage
);
export const ContractManufacturerGridCurrentPageSizeSelector = createSelector(
  getAdminState,
  (state) => state?.cmGrid?.pageSize
);

export const selectedCMFiltersSelector = createSelector(
  ContractManufacturerGridDFilterSelector,
  ContractManufacturerGridSortSelector,
  ContractManufacturerGridCurrentPageSelector,
  ContractManufacturerGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);
/*-----------------------------------------------invited user-------------------------------------------*/

export const InvitedGridDFilterSelector = createSelector(
  getAdminState,
  (state) => state?.InvitedGrid?.filter
);

export const InvitedGridSortSelector = createSelector(
  getAdminState,
  (state) => state?.InvitedGrid?.sort
);

export const InvitedGridEditSelector = createSelector(
  getAdminState,
  (state) => state?.InvitedGrid?.edit
);

export const InvitedGridCurrentPageSelector = createSelector(
  getAdminState,
  (state) => state?.InvitedGrid?.currentPage
);

export const InvitedGridCurrentPageSizeSelector = createSelector(
  getAdminState,
  (state) => state?.InvitedGrid?.pageSize
);

export const InvitedselectedFiltersSelector = createSelector(
  InvitedGridDFilterSelector,
  InvitedGridSortSelector,
  InvitedGridCurrentPageSelector,
  InvitedGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);

export const InvitedGridDataSelector = createSelector(
  getAdminState,
  (state) => state?.InvitedGrid
);
export const InvitedGridDeleteSelector = createSelector(
  getAdminState,
  (state) => state?.InvitedGrid?.delete
);
export const InvitedGridResendSelector = createSelector(
  getAdminState,
  (state) => state?.resend
);

/*-----------------------------------------------registered user-------------------------------------------*/
export const RegisteredGridDataSelector = createSelector(
  getAdminState,
  (state) => state?.RegisteredGrid
);
export const RegisteredGridDeleteSelector = createSelector(
  getAdminState,
  (state) => state?.RegisteredGrid?.delete
);
export const RegisteredGridExportSelector = createSelector(
  getAdminState,
  (state) => state?.RegisteredGrid?.export
);
export const RegisteredGridEditSelector = createSelector(
  getAdminState,
  (state) => state?.RegisteredGrid?.edit
);

export const RegisteredGridDFilterSelector = createSelector(
  getAdminState,
  (state) => state?.RegisteredGrid?.filter
);
export const GetUserEmailSelector = createSelector(
  getAdminState,
  (state) => state?.userEmail
);

export const RegisteredGridSortSelector = createSelector(
  getAdminState,
  (state) => state?.RegisteredGrid?.sort
);

export const RegisteredGridCurrentPageSelector = createSelector(
  getAdminState,
  (state) => state?.RegisteredGrid?.currentPage
);

export const RegisteredGridCurrentPageSizeSelector = createSelector(
  getAdminState,
  (state) => state?.RegisteredGrid?.pageSize
);

export const RegisteredselectedFiltersSelector = createSelector(
  RegisteredGridDFilterSelector,
  RegisteredGridSortSelector,
  RegisteredGridCurrentPageSelector,
  RegisteredGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);
/**----------------------------------------------BUISNESS UNIT----------------------------------------- */

export const BuisnessUnitGridDataSelector = createSelector(
  getAdminState,
  (state) => state?.buisnessUnitGrid
);

export const BuisnessUnitGridDFilterSelector = createSelector(
  getAdminState,
  (state) => state?.buisnessUnitGrid?.filter
);
export const GetValidationErrorSelector = createSelector(
  getAdminState,
  (state) => state?.validationError
);
export const GetTicketTypeSelector = createSelector(
  getAdminState,
  (state) => state?.ticketType
);
export const GetTicketYearSelector = createSelector(
  getAdminState,
  (state) => state?.ticketYear
);
export const GetTicketAssigneeSelector = createSelector(
  getAdminState,
  (state) => state?.ticketAssignee
);
export const BuisnessUnitGridSortSelector = createSelector(
  getAdminState,
  (state) => state?.buisnessUnitGrid?.sort
);

export const BuisnessUnitGridDeleteSelector = createSelector(
  getAdminState,
  (state) => state?.buisnessUnitGrid?.delete
);

export const BuisnessUnitGridEditSelector = createSelector(
  getAdminState,
  (state) => state?.buisnessUnitGrid?.edit
);

export const BuisnessUnitGridCurrentPageSelector = createSelector(
  getAdminState,
  (state) => state?.buisnessUnitGrid?.currentPage
);
export const BuisnessUnitGridCurrentPageSizeSelector = createSelector(
  getAdminState,
  (state) => state?.buisnessUnitGrid?.pageSize
);

export const selectedBuisnessUnitFiltersSelector = createSelector(
  BuisnessUnitGridDFilterSelector,
  BuisnessUnitGridSortSelector,
  BuisnessUnitGridCurrentPageSelector,
  BuisnessUnitGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);

/**----------------------------------------------COM----------------------------------------- */

export const ComGridDataSelector = createSelector(
  getAdminState,
  (state) => state?.comGrid
);

export const ComGridDFilterSelector = createSelector(
  getAdminState,
  (state) => state?.comGrid?.filter
);

export const ComGridSortSelector = createSelector(
  getAdminState,
  (state) => state?.comGrid?.sort
);

export const ComGridDeleteSelector = createSelector(
  getAdminState,
  (state) => state?.comGrid?.delete
);

export const ComGridEditSelector = createSelector(
  getAdminState,
  (state) => state?.comGrid?.edit
);

export const ComGridCurrentPageSelector = createSelector(
  getAdminState,
  (state) => state?.comGrid?.currentPage
);
export const ComGridCurrentPageSizeSelector = createSelector(
  getAdminState,
  (state) => state?.comGrid?.pageSize
);

export const selectedCOMFiltersSelector = createSelector(
  ComGridDFilterSelector,
  ComGridSortSelector,
  ComGridCurrentPageSelector,
  ComGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);

/**----------------------------------------------MAN----------------------------------------- */

export const ManGridDataSelector = createSelector(
  getAdminState,
  (state) => state?.manGrid
);

export const ManGridDFilterSelector = createSelector(
  getAdminState,
  (state) => state?.manGrid?.filter
);

export const ManGridSortSelector = createSelector(
  getAdminState,
  (state) => state?.manGrid?.sort
);

export const ManGridDeleteSelector = createSelector(
  getAdminState,
  (state) => state?.manGrid?.delete
);

export const ManGridEditSelector = createSelector(
  getAdminState,
  (state) => state?.manGrid?.edit
);

export const ManGridCurrentPageSelector = createSelector(
  getAdminState,
  (state) => state?.manGrid?.currentPage
);

export const ManGridCurrentPageSizeSelector = createSelector(
  getAdminState,
  (state) => state?.manGrid?.pageSize
);

export const selectedFiltersSelector = createSelector(
  ManGridDFilterSelector,
  ManGridSortSelector,
  ManGridCurrentPageSelector,
  ManGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);

/**----------------------------------------------DIS----------------------------------------- */
export const DisGridDataSelector = createSelector(
  getAdminState,
  (state) => state?.disGrid
);

export const DisGridDFilterSelector = createSelector(
  getAdminState,
  (state) => state?.disGrid?.filter
);

export const DisGridSortSelector = createSelector(
  getAdminState,
  (state) => state?.disGrid?.sort
);

export const DisGridDeleteSelector = createSelector(
  getAdminState,
  (state) => state?.disGrid?.delete
);

export const DisGridEditSelector = createSelector(
  getAdminState,
  (state) => state?.disGrid?.edit
);

export const DisGridAddSelector = createSelector(
  getAdminState,
  (state) => state?.disGrid?.add
);
export const DisGridCurrentPageSelector = createSelector(
  getAdminState,
  (state) => state?.disGrid?.currentPage
);

export const DisGridCurrentPageSizeSelector = createSelector(
  getAdminState,
  (state) => state?.disGrid?.pageSize
);
export const SelectedDisFiltersSelector = createSelector(
  DisGridDFilterSelector,
  DisGridSortSelector,
  ManGridCurrentPageSelector,
  ManGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);

/**----------------------------------------------MAN-DIS----------------------------------------- */

export const ManDisGridDataSelector = createSelector(
  getAdminState,
  (state) => state?.manDisGrid
);

export const ManDisGridDFilterSelector = createSelector(
  getAdminState,
  (state) => state?.manDisGrid?.filter
);

export const ManDisGridSortSelector = createSelector(
  getAdminState,
  (state) => state?.manDisGrid?.sort
);
export const ManDisGridDeleteSelector = createSelector(
  getAdminState,
  (state) => state?.manDisGrid?.delete
);

export const ManDisGridEditSelector = createSelector(
  getAdminState,
  (state) => state?.manDisGrid?.edit
);

export const ManDisGridCurrentPageSelector = createSelector(
  getAdminState,
  (state) => state?.manDisGrid?.currentPage
);

export const ManDisGridCurrentPageSizeSelector = createSelector(
  getAdminState,
  (state) => state?.manDisGrid?.pageSize
);

export const selectedManDisFiltersSelector = createSelector(
  ManDisGridDFilterSelector,
  ManDisGridSortSelector,
  ManDisGridCurrentPageSelector,
  ManDisGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);

/**---------------------------------------------------YEAR-CURRENCY----------------------------------- */
export const YearCurrGridEditSelector = createSelector(
  getAdminState,
  (state) => state?.yearCurrGrid?.edit
);

export const ExchangeGridDateSelector = createSelector(
  getAdminState,
  (state) => state?.yearCurrGrid
);

export const ExchangeDeleteSelector = createSelector(
  getAdminState,
  (state) => state?.yearCurrGrid?.delete
);

export const ExchangeEditSelector = createSelector(
  getAdminState,
  (state) => state?.yearCurrGrid?.edit
);

export const ExchangeGridCurrentPageSelector = createSelector(
  getAdminState,
  (state) => state?.yearCurrGrid?.currentPage
);
export const ExchangeGridCurrentPageSizeSelector = createSelector(
  getAdminState,
  (state) => state?.yearCurrGrid?.pageSize
);

export const ExchangeGridSortSelector = createSelector(
  getAdminState,
  (state) => state?.yearCurrGrid?.sort
);

export const selectedExchangeFiltersSelector = createSelector(
  ExchangeGridDateSelector,
  ExchangeGridSortSelector,
  ExchangeGridCurrentPageSelector,
  ExchangeGridCurrentPageSizeSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        ...filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filters: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);

/**---------------------------------------------------CM-CURRENCY----------------------------------- */
export const CMGridEditSelector = createSelector(
  getAdminState,
  (state) => state?.cmGrid?.edit
);
/**------------------------------------------------------------DDL----------------------------------- */
export const ManufacturerSelector = createSelector(
  getAdminState,
  (state) => state?.manufacturers
);

export const DistributerSelector = createSelector(
  getAdminState,
  (state) => state?.distributers
);

export const CommodotiesSelector = createSelector(
  getAdminState,
  (state) => state?.commodoties
);
export const CommodotiesOwnersSelector = createSelector(
  getAdminState,
  (state) => state?.commodityOwnersList
);
export const StatusSelector = createSelector(
  getAdminState,
  (state) => state?.manStatus
);
export const TypeSelector = createSelector(
  getAdminState,
  (state) => state?.disType
);


/////////////////////////////////////////LOA//////////////////////////////////////
export const LOAGridDataSelector = createSelector(
  getAdminState,
  (state) => state?.loaGrid
);

export const LOAGridDFilterSelector = createSelector(
  getAdminState,
  (state) => state?.loaGrid?.filter
);

export const LOAGridSortSelector = createSelector(
  getAdminState,
  (state) => state?.loaGrid?.sort
);

export const LOAGridCurrentPageSelector = createSelector(
  getAdminState,
  (state) => state?.loaGrid?.currentPage
);

export const LOAGridCurrentPageSizeSelector = createSelector(
  getAdminState,
  (state) => state?.loaGrid?.pageSize
);

export const ShareLOAsSelector = createSelector(
  getAdminState,
  (state) => state?.gridActionPopupData
);
export const LOAExportSelector = createSelector(
  getAdminState,
  (state) => state?.loaGrid?.export
);


//////////////////////////////////////////MASSIVE UPLOAD/////////////////////////////

export const fileStatusResultSelector = createSelector(
  getAdminState,
  (state) => state?.fileStatus
);
export const discardResultSelector = createSelector(
  getAdminState,
  (state) => state?.discard
);
export const normalizationResultSelector = createSelector(
  getAdminState,
  (state) => state?.normalizationResult
);

export const currentActionSelector = createSelector(
  getAdminState,
  (state) => state?.currentAction
);


export const uploadedFileResultSelector = createSelector(
  getAdminState,
  (state) => state?.uploadedFileResult
);

export const mapUploadedFileResultSelector = createSelector(
  getAdminState,
  (state) => state?.mapUploadedFileResult
);


export const fileSubmitedSelector = createSelector(
  getAdminState,
  (state) => state?.fileSubmited
);

//////////////////////////////////////////////////GRID ACTIONS////////////////////////////////////////////
export const LoadGridDataSelector = createSelector(
  getAdminState,
  (state) => state?.dataGrid
);

export const GridDeleteRecordSelector = createSelector(
  getAdminState,
  (state) => state?.dataGrid?.delete
);
export const GridFilterRecordSelector = createSelector(
  getAdminState,
  (state) => state?.dataGrid?.filter
);
export const GridSortRecordSelector = createSelector(
  getAdminState,
  (state) => state?.dataGrid?.sort
);
export const GridPageChangesRecordSelector = createSelector(
  getAdminState,
  (state) => state?.dataGrid?.currentPage
);
export const GridCurrentPageSizeRecordSelector = createSelector(
  getAdminState,
  (state) => state?.dataGrid?.pageSize
);
export const selectedRecordFiltersSelector = createSelector(
  GridFilterRecordSelector,
  GridSortRecordSelector,
  GridPageChangesRecordSelector,
  GridCurrentPageSizeRecordSelector,
  (filter, sort, page, pageSize) => {
    if (filter || sort || page || pageSize) {
      return {
        filter,
        sort,
        page,
        pageSize,
      };
    }
    return {
      filter: undefined,
      sort: undefined,
      page: 0,
      pageSize: 10,
    };
  }
);
// export const GridEditSelector = createSelector(
//   getAdminState,
//   (state) => state?.dataGrid?.edit
// );

export const GridAddEditSelector = createSelector(
  getAdminState,
  (state) => state?.dataGrid?.add
);