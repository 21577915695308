import { IGridRequest } from 'src/app/shared/shared.interface';
import {
  IMappedDataRequest,
  IMappedSupplierDataRequest,
  ISupplierMappedDataRequest,
} from './upload.interface';

export const UPLOAD_TICKET_ID = 'upload-ticket-id';
export const UPLOAD_FILE_ID = 'upload-file-id';
export const UPLOAD_EXISTING_ID = 'upload-existing-id';

export const UPLOAD_QUOTE_ID = 'upload-ticket-id';

export function normalizedGridRequest(page?: number, size?: number) {
  const request: IGridRequest = {
    page: page || 0,
    size: size || 10,
  };

  return request;
}

export function MappedDataRequest(
  mappedColumns: any,
  start: any,
  fileId: number,
  ticketId?:any
) {
  if(ticketId==undefined){
    ticketId=sessionStorage.getItem(UPLOAD_TICKET_ID);
  }
  else{
    ticketId=ticketId
  }

  const request: IMappedDataRequest = {
    
    ticketId:ticketId,
    fileId: fileId + '',
    startRow: start,
    cmMappedColumns: mappedColumns,
  };

  return request;
}

export function MappedSupplierDataRequest(
  mappedColumns: any,
  startRow: any,
  fileId: any,
  tempResponseId: any,
  quoteId: any
) {
  const request: ISupplierMappedDataRequest = {
    columns: mappedColumns,
    startRow: startRow,
    fileId: fileId + '',
    tempResponseId: tempResponseId,
    quoteId: quoteId,
  };

  return request;
}
