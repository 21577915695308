<form [formGroup]="addInvitationForm">
<div class="form-group row">
    <label class="col-3 col-form-label"
      >Email <span class="astric">*</span></label
    >
    <kendo-textbox
      class="col-9"
      placeholder="Email"
      name="Email"
      [(ngModel)]="Email"
      required
      formControlName="email"
    >
    </kendo-textbox>
    <app-error-message message="email is required" *ngIf="IsSaveValid || (addInvitationForm.touched &&Email=='')" [isError]="true"></app-error-message>
    <app-error-message message="email is invalid" *ngIf="addInvitationForm.touched &&hasError('email','emailFormat')" [isError]="true"></app-error-message>
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label"
      >Company Type<span class="astric">*</span></label
    >
    <app-dropdown-list
      class="col-9"
      [data]="CompanyTypes"
      textField="companyTypeName"
      valueField="companyTypeId"
      [valuePrimitive]="true"
      (selectionChange)="getSelectedCompanyTypeItem($event)"
      [selectedItem]="companyId"
      formControlName="companyTypeId"

     >
    </app-dropdown-list>
    <app-error-message message="company type is required" *ngIf="addInvitationForm.touched &&companyId==0" [isError]="true"></app-error-message>

  
  </div>
  <div class="form-group row">
    <label class="col-3 col-form-label"
      >Company <span class="astric">*</span></label
    >
    <kendo-autocomplete
        [data]="CompanyList"         
        class="col-9"
        [disabled]="IsDisabled"
        [(ngModel)]="CompanyName"
        placeholder="Company"
         [value]="CompanyName"
        [filterable]="true"
        (filterChange)="click($event)"
        formControlName="companyName"
       >
      </kendo-autocomplete>
    <app-error-message message="company  is required" *ngIf="addInvitationForm.touched &&hasError('companyName','required')" [isError]="true"></app-error-message>

  </div>
  
  <div class="form-group row">
    <label class="col-3 col-form-label"
      >Role<span class="astric">*</span></label
    >
    <app-dropdown-list
      class="col-9"
      [data]="RolelistItems"
      textField="roleName"
      valueField="roleId"
      [valuePrimitive]="true"
      (selectionChange)="getSelectedRole($event)"
      [selectedItem]="role"
      formControlName="roleId"
    >
    </app-dropdown-list>
    <app-error-message message="role  is required" *ngIf="addInvitationForm.touched &&role==0" [isError]="true"></app-error-message>

  </div>
  <app-error-message message={{erorrMessage}} *ngIf="this.IsError" [isError]="true"></app-error-message>
  
</form>