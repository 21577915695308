export enum Dialog {
  submit = 'Submit',
  disable = 'Disable',
  Dicard = 'Discard',
  Proceed = 'Proceed',
  Resend = 'Resend',
  enable = 'Enable',
  close = 'Close',
  open = 'Open',
  approve = 'Approve',
  reject = 'Reject',
  hasNotSubmitButton = 'hasSubmitButton'
}


